import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { HealthScoreColor } from './HealthScroreColorEnum';
ChartJS.register(ArcElement, Tooltip, Legend);
const convertColorStringToColor = (color) => {
    if (color === HealthScoreColor.green) {
        return {
            background: 'rgba(75, 192, 192, 0.2)',
            border: 'rgba(75, 192, 192, 1)',
        };
    }
    if (color === HealthScoreColor.yellow) {
        return {
            background: 'rgba(255, 206, 86, 0.2)',
            border: 'rgba(255, 206, 86, 1)',
        };
    }
    if (color === HealthScoreColor.red) {
        return {
            background: 'rgba(255, 99, 132, 0.2)',
            border: 'rgba(255, 99, 132, 1)',
        };
    }
    throw new Error('This color does not exist ' + color);
};
const data = (data) => {
    const score = Number(data.score);
    const color = convertColorStringToColor(data.color);
    return {
        labels: ['Ergebnis', ''],
        datasets: [
            {
                label: 'Gesamtbewertung',
                data: [score, 100 - score],
                backgroundColor: [
                    color.background,
                    'rgb(236,236,236, 0.2)',
                ],
                borderColor: [
                    color.border,
                    'rgb(236,236,236, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };
};
const HealthScoreDoughnutChart = (props) => {
    return <Doughnut width={props.width} data={data(props)} plugins={[
            {
                id: 'cutOutValue',
                beforeDraw(chart) {
                    chart.ctx.restore();
                    const fontSize = (chart.height / 80).toFixed(2);
                    chart.ctx.font = fontSize + 'em sans-serif';
                    chart.ctx.fillStyle = '#00000';
                    chart.ctx.textBaseline = 'middle';
                    const text = chart.data.datasets[0]?.data[0]?.toString() || '', textX = Math.round((chart.width - chart.ctx.measureText(text).width) / 2), textY = chart.height / 2;
                    chart.ctx.fillText(text, textX, textY);
                    chart.ctx.save();
                }
            }
        ]} options={{
            responsive: true,
            cutout: '50%',
            plugins: {
                title: { display: false },
                legend: { display: false, title: { display: false } },
                tooltip: {
                    filter: tooltipItem => tooltipItem.dataIndex === 0,
                }
            },
        }}/>;
};
HealthScoreDoughnutChart.displayName = 'HealthScoreDoughnutChart';
export default HealthScoreDoughnutChart;
