import * as React from 'react';
import { useEffect, useState } from 'react';
import VideoCategoryList from './VideoCategoryList';
import { getMediaElements } from '../../../Utilities/mediaElements';
import pThrottle from 'p-throttle';
const throttle = pThrottle({
    limit: 1,
    interval: 200,
});
const Mediathek = ({ product, searchKey, categories = [], categoriesSorting = [], forceUseSlider, sortByDate, filterByType, maxAnzPerPage, disableInfinity }) => {
    const [videos, setVideos] = useState([]);
    const [uniqueCategories, setUniqueCategories] = useState([]);
    const filteredCategories = categories.filter(Boolean).map((str) => decodeURI(str));
    const fetchElements = throttle(async () => {
        const { elements } = await getMediaElements(searchKey, '', [], filteredCategories, filterByType, maxAnzPerPage);
        setVideos(elements);
        if (elements.length > 0 && uniqueCategories.length === 0) {
            const categories = [];
            for (const video of elements) {
                if (video.hasOwnProperty('categories')) {
                    for (const cat of video.categories) {
                        if (filteredCategories.length === 0) {
                            continue;
                        }
                        if (!filteredCategories.includes(cat.title)) {
                            continue;
                        }
                        categories.push(cat.title);
                    }
                }
            }
            setUniqueCategories(categories.filter((value, index, self) => {
                return self.indexOf(value) === index;
            }));
        }
    });
    useEffect(() => {
        fetchElements();
    }, []);
    if (videos.length > 0 && uniqueCategories.length > 0) {
        return <div>
            <VideoCategoryList videos={videos} categories={uniqueCategories} categoriesSorting={categoriesSorting} sortByDate={sortByDate} productAsSlider={uniqueCategories.length === 0 && forceUseSlider ? product : undefined} disableInfinity={disableInfinity}/>
        </div>;
    }
    return <></>;
};
Mediathek.displayName = 'Mediathek';
export default Mediathek;
