import * as React from 'react';
import { useEffect, useState } from 'react';
import GlobalSearchMegaFlyout from './GlobalSearchMegaFlyout';
import pThrottle from 'p-throttle';
import { getMediaElementsWithProps } from '../../../Utilities/mediaElements';
const throttle = pThrottle({
    limit: 1,
    interval: 200,
});
export const GlobalsSearchContext = React.createContext(null);
const ITEMS_PER_PAGE = 6;
const GlobalSearch = () => {
    const [mediaElements, setMediaElements] = useState([]);
    const [isFetching, setIsFetching] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [searchString, setSearchString] = useState('');
    const [globalActiveFilter, setGlobalActiveFilter] = useState({ types: [], specialFor: [], duration: [] });
    const [filterReset, setFilterReset] = useState(false);
    const [resultCount, setResultCount] = useState(0);
    const [filterItems, setFilterItems] = useState({
        // named after the fields in search api, maps to types, specialFor and duration
        duration: [],
        videoTypes: [],
        location: [],
    });
    useEffect(() => {
        fetchMediaElements().then();
    }, [globalActiveFilter, searchString]);
    const fetchMediaElements = throttle(async () => {
        if (isFetching || !isOpen) {
            return;
        }
        setIsFetching(true);
        const result = await getMediaElementsWithProps({
            query: searchString,
            videoTypes: globalActiveFilter.types,
            duration: globalActiveFilter.duration.join('|'),
            location: globalActiveFilter.specialFor.join('|'),
            itemsPerPage: ITEMS_PER_PAGE,
        });
        setMediaElements(result.data);
        setIsFetching(false);
        setResultCount(result.count);
        if (result.aggregations) {
            setFilterItems({
                duration: result.aggregations.duration.map((item) => {
                    return { key: item.key, label: item.key, count: item.doc_count };
                }),
                videoTypes: result.aggregations.videoTypes.map((item) => {
                    return { key: item.key, label: item.key, count: item.doc_count };
                }),
                location: result.aggregations.location.map((item) => {
                    return { key: item.key, label: item.key, count: item.doc_count };
                }),
            });
        }
    });
    const handleOpenMegaFlyout = () => {
        setIsOpen(true);
    };
    const handleCloseMegaFlyout = () => {
        setIsOpen(false);
    };
    const handleMegaFlyoutState = () => {
        setIsOpen(!isOpen);
    };
    const handleInputChange = (event) => {
        setSearchString(event.target.value);
    };
    const handleFilterChange = (id, state) => {
        const newState = { [id]: state };
        setGlobalActiveFilter({
            ...globalActiveFilter,
            ...newState
        });
    };
    const handleFilterReset = () => {
        setGlobalActiveFilter({
            types: [],
            specialFor: [],
            duration: []
        });
        setFilterReset(!filterReset);
    };
    return <div>
        <div className="hidden lg:block flex justify-start px-8 w-full">
            <div className="relative text-gray-400 focus-within:text-gray-600 w-full">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 text-[#00537e]">
                    <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"></path>
                    </svg>
                </div>
                <input id="search" onClick={handleOpenMegaFlyout} onChange={handleInputChange} className="block w-full rounded-md border border-gray-400 bg-white py-1.5 pl-10 pr-3 text-gray-900 shadow-sm" placeholder="Suche" type="search" name="search"/>
            </div>
        </div>
        <div className="block lg:hidden flex justify-start w-full">
            <button onClick={handleMegaFlyoutState} className="inline-block lg:hidden w-10 h-10 text-brand-primary hover:text-brand-secondary p-1">
                <svg viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"></path>
                </svg>
            </button>
        </div>
        <GlobalsSearchContext.Provider value={{
            isOpen,
            isFetching,
            filterItems,
            mediaElements,
            resultCount,
            handleCloseMegaFlyout,
            handleInputChange,
            searchString,
            globalActiveFilter,
            handleFilterChange,
            handleFilterReset,
            filterReset
        }}>
            <GlobalSearchMegaFlyout />
        </GlobalsSearchContext.Provider>
    </div>;
};
GlobalSearch.displayName = 'GlobalSearch';
export default GlobalSearch;
