import * as React from 'react';
import { lazy, Suspense } from 'react';
const LazyIcon = lazy(() => import('./Icon'));
const IconSet = ({ icons }) => {
    return <div className="flex items-center">
        {icons.map(({ id, iconIsLocal, icon_is_local, icon, tooltip }) => <div className="inline-flex" key={id}>
                <div data-tooltip-id="icon-set-tooltip" data-tooltip-content={tooltip} className="inline-flex items-center justify-center w-6 h-6 mr-2 text-white transition-colors duration-150 bg-gray-900 rounded-full focus:shadow-outline hover:bg-gray-700 hover:cursor-pointer">
                    {iconIsLocal || icon_is_local
                ? <Suspense fallback={<></>}>
                            <LazyIcon iconName={icon}></LazyIcon>
                        </Suspense>
                : <i className={`text-xs ${icon}`}></i>}
                </div>
            </div>)}
    </div>;
};
IconSet.displayName = 'IconSet';
export default IconSet;
