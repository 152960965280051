import * as React from 'react';
import ChallengeMediaElement from './ChallengeMediaElement';
const VideoChallengeGrid = ({ videos, videoChallenge, mediaElementClickCallback, }) => {
    const elements = [];
    if (!elements.length) {
        const videoElements = [];
        for (const video of videos) {
            videoElements.push(<ChallengeMediaElement key={video.id} video={video} videoChallenge={videoChallenge} mediaElementClickCallback={mediaElementClickCallback}/>);
        }
        elements.push({ title: '', videos: videoElements, description: '' });
    }
    return <section className="mx-auto">
        {elements.map(({ title, videos }) => <div key={title} className="mx-auto grid max-w-2xl auto-rows-fr grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-5">
            {videos}
        </div>)}
    </section>;
};
VideoChallengeGrid.displayName = 'VideoChallengeGrid';
export default VideoChallengeGrid;
