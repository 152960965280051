import { generateUrl } from './api';
export const buildQuery = (props, url = '') => {
    const data = {};
    if (props.query) {
        data['q'] = props.query;
    }
    if (typeof props.filterByTypes !== 'undefined' && props.filterByTypes.length > 0) {
        data['type'] = props.filterByTypes.join('|');
    }
    if (typeof props.videoTypes !== 'undefined' && props.videoTypes.length > 0) {
        data['types'] = props.videoTypes.join('|');
    }
    if (typeof props.categories !== 'undefined' && props.categories.length > 0) {
        data['categories'] = props.categories.join('|');
    }
    if (typeof props.tags !== 'undefined' && props.tags.length > 0) {
        data['tags'] = props.tags.join('|');
    }
    if (typeof props.hiddenTags !== 'undefined' && props.hiddenTags.length > 0) {
        data['hidden_tags'] = props.hiddenTags.join('|');
    }
    if (props.excludeType) {
        data['exclude_type'] = props.excludeType;
    }
    if (props.location) {
        data['location'] = props.location;
    }
    if (props.duration) {
        data['duration'] = props.duration;
    }
    if (props.product) {
        data['product'] = props.product;
    }
    if (props.itemsPerPage) {
        data['limit_per_page'] = props.itemsPerPage;
    }
    if (props.page) {
        data['page'] = props.page;
    }
    if (url) {
        return generateUrl(url, data);
    }
    if (props.product) {
        return generateUrl('sitzkrieger_search_searchelements_product', data);
    }
    return generateUrl('sitzkrieger_search_searchelements', data);
};
