import BaseElement from './BaseElement';
import * as Modal from 'react-modal';
class ModalElement extends BaseElement {
    constructor() {
        super();
        // @ts-ignore
        Modal.setAppElement(this);
    }
}
export default ModalElement;
