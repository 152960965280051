import * as React from 'react';
const MediaElementDetails = ({ video }) => {
    return <>
        {video.attachments && video.attachments.length > 0 && (<div className="rounded-lg bg-gray-100 shadow p-6">
                <h2 className="mb-4 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                Downloads
                </h2>
                <ul role="list" className="grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-3">
                    {video.attachments.map((attachment, index) => (<li key={index} className="relative col-span-1 flex rounded-md shadow-sm">
                            <div className="flex w-12 flex-shrink-0 items-center justify-center bg-brand-primary rounded-l-md text-2xl font-medium text-white">
                                <i className={`fa-solid ${attachment.icon}`}></i>
                            </div>
                            <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
                                <div className="flex-1 truncate px-4 py-2 text-sm">
                                    <a href={attachment.filePath} download title={attachment.title} className="font-medium text-gray-900 hover:text-gray-600">
                                        <span className="absolute inset-0" aria-hidden="true"></span>
                                        {attachment.title}
                                    </a>
                                    <p className="text-gray-500">
                                        {attachment.fileSize}
                                    </p>
                                </div>
                            </div>
                        </li>))}
                </ul>
            </div>)}
    </>;
};
MediaElementDetails.displayName = 'MediaElementDetails';
export default MediaElementDetails;
