import * as React from 'react';
const VideoChallengeProgressBar = ({ progress }) => {
    const filledSpaceStyleAttrib = {
        width: (progress == 0 ? progress + 5 : progress) + '%'
    };
    const emptySpaceStyleAttrib = {
        width: (100 - progress) + '%'
    };
    return <>
        <div className="flex flex-col gap-3 rounded-lg bg-gray-100 shadow p-4">
            <span className="font-semibold text-gray-900">
                Dein Fortschritt
            </span>
            <ol className="flex items-center w-full">
                <li className="flex items-center after:content-[\'\'] after:w-full after:h-1 after:border-b after:border-8 after:inline-block after:border-emerald-600" style={filledSpaceStyleAttrib}>
                    <span className="flex items-center justify-center w-8 h-8 rounded-full bg-emerald-600 shrink-0">
                        <i className="fa-regular fa-snooze text-white text-lg"></i>
                    </span>
                </li>
                {progress <= 97 &&
            <li className="flex items-center after:content-[\'\'] after:w-full after:h-1 after:border-b after:border-gray-300 after:border-8 after:inline-block" style={emptySpaceStyleAttrib}>
                        <span className="flex items-center justify-center rounded-full h-8 w-8 ring-2 ring-emerald-600 z-10 shrink-0">
                            <i className="fa-solid fa-person-running-fast text-emerald-600 text-lg"></i>
                        </span>
                    </li>}
                <li className="flex items-center">
                    <span className="flex items-center justify-center rounded-full h-8 w-8 bg-emerald-600 shrink-0">
                        <i className="fa-solid fa-flag-checkered text-white text-lg"></i>
                    </span>
                </li>
            </ol>

            <div className="flex justify-start items-center w-full">
                <span className="text-sm pl-3 font-medium text-gray-700" style={filledSpaceStyleAttrib}></span>
                {progress <= 97 &&
            <span className="text-sm pl-3 font-medium text-gray-700" style={emptySpaceStyleAttrib}>{progress}%</span>}
                <span className="text-sm pl-3 font-medium text-gray-700">100%</span>
            </div>
        </div>
    </>;
};
VideoChallengeProgressBar.displayName = 'VideoChallengeProgressBar';
export default VideoChallengeProgressBar;
