import BaseElement from './BaseElement';
import ToolTip from '../Components/Mediathek/ToolTip';
class ToolTipElement extends BaseElement {
    constructor() {
        super(...arguments);
        this.element = ToolTip;
    }
}
export const loadToolTipWebComponent = () => {
    customElements.define('tool-tip', ToolTipElement);
};
