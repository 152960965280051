import * as React from 'react';
import PostCardVideo from './PostCardVideo';
const PostCard = ({ postCard, updateModalTitle }) => {
    updateModalTitle(postCard.title);
    return <div>
        <div style={{ minHeight: '300px' }}>
            {postCard.video === null ? <>
                <img alt={postCard.title} src={postCard.frontSrc}/>
            </> : <>
                <PostCardVideo postcard={postCard} props={{ controls: true, width: '100%', height: '300px' }}/>
            </>}
        </div>
    </div>;
};
PostCard.displayName = 'PostCard';
export default PostCard;
