import { useQuery } from '@tanstack/react-query';
import { getCourseProgress } from '../../../../Utilities/api';
import { useUserDefaultCourse } from './useUserDefaultCourse';
export const useCourseProgress = (courseUuid) => {
    const { isLoading: isDefaultCourseLoading, data: defaultCourseData } = useUserDefaultCourse();
    const effectiveCourseUuid = courseUuid || defaultCourseData?.uuid;
    return useQuery({
        queryKey: ['courseProgress', effectiveCourseUuid],
        queryFn: () => getCourseProgress(effectiveCourseUuid),
        enabled: !!effectiveCourseUuid && !isDefaultCourseLoading,
    });
};
