import { buildQuery } from './buildQuery';
export const getMediaElements = async (product, query, tags = [], categories = [], filterByType = '', maxAnzPerPage = '') => {
    const url = buildQuery({
        product: product,
        query: query,
        tags: tags,
        categories: categories,
        filterByTypes: [filterByType],
        itemsPerPage: Number(maxAnzPerPage),
    });
    const response = await fetch(url);
    const json = await response.json();
    return {
        elements: json.data,
        tags: json.aggregations.tags,
        duration: json.aggregations.duration,
        count: json.count,
    };
};
export const getMediaElementsWithProps = async (props) => {
    const url = buildQuery(props);
    const response = await fetch(url);
    return await response.json();
};
