import * as React from 'react';
import { Fragment, useEffect, useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { GlobalsSearchContext } from './GlobalSearch';
const GlobalSearchFilter = ({ id, name, options }) => {
    // todo: something about these state-methods doesnt seem right
    const { globalActiveFilter, handleFilterChange, handleFilterReset, filterReset } = React.useContext(GlobalsSearchContext);
    const [activeFilter, setActiveFilter] = useState((id in globalActiveFilter ? globalActiveFilter[id] : []));
    const [activeFilterCount, setActiveFilterCount] = useState(0);
    useEffect(() => {
        handleFilterChange(id, activeFilter);
        setActiveFilterCount(activeFilter.length);
        if (filterReset) {
            handleLocalFilterReset();
            handleFilterReset();
        }
    }, [id, activeFilter, filterReset]);
    const handleChange = (event) => {
        if (event.target.checked) {
            addFilter(event);
        }
        else {
            removeFilter(event);
        }
    };
    const addFilter = (event) => {
        const filters = [
            ...activeFilter,
            event.target.value
        ];
        setActiveFilter(Array.from(new Set(filters)));
    };
    const removeFilter = (event) => {
        const array = [...activeFilter];
        const index = array.indexOf(event.target.value);
        if (index !== -1) {
            array.splice(index, 1);
            setActiveFilter(array);
        }
    };
    const isChecked = (value) => {
        return activeFilter.indexOf(value) >= 0;
    };
    const handleLocalFilterReset = () => {
        setActiveFilterCount(0);
        setActiveFilter([]);
    };
    return <Popover key={id} className="relative inline-block px-4 text-left">
        <Popover.Button className="group inline-flex justify-center items-center text-md font-medium text-gray-700 hover:text-gray-900">
            <span>{name}</span>
            {activeFilterCount > 0
            ? <span className="ml-1.5 rounded bg-gray-200 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-gray-700">{activeFilterCount}</span>
            : <></>}
            <svg className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"> <path d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"/></svg>
        </Popover.Button>

        <Transition as={Fragment} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
            <Popover.Panel className="absolute left-0 z-10 mt-2 origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                <form className="space-y-4">
                    {options.map((option, optionIdx) => (<div key={option.value} className="flex items-center">
                            <input id={`filter-${id}-${optionIdx}`} name={`${id}[]`} defaultValue={option.value} type="checkbox" checked={isChecked(option.value)} className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500" onChange={handleChange}/>
                            <label htmlFor={`filter-${id}-${optionIdx}`} className="ml-3 whitespace-nowrap pr-6 text-md font-medium text-gray-900">
                                {option.label}
                            </label>
                        </div>))}
                </form>
            </Popover.Panel>
        </Transition>
    </Popover>;
};
GlobalSearchFilter.displayName = 'GlobalSearchFilter';
export default GlobalSearchFilter;
