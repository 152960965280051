import * as React from 'react';
import CustomSlider from './CustomSlider';
import MediaElement from './MediaElement';
import BaseElement from '../../CustomElements/BaseElement';
const LiveplayerSlider = ({ videos, disableInfinity }) => {
    const videoElements = [];
    for (const video of videos) {
        videoElements.push(<MediaElement key={video.id} video={video}/>);
    }
    if (videoElements.length === 0) {
        return <></>;
    }
    return <>
        <CustomSlider slidesXl={4} slidesLg={3} slidesMd={2} slidesSm={1} infinity={!disableInfinity} count={videoElements.length}>
            {videoElements}
        </CustomSlider>
    </>;
};
LiveplayerSlider.displayName = 'LiveplayerSlider';
export default LiveplayerSlider;
class LiveplayerSliderElement extends BaseElement {
    constructor() {
        super(...arguments);
        this.element = LiveplayerSlider;
    }
}
export const loadLiveplayerSlider = () => {
    customElements.define('liveplayer-slider', LiveplayerSliderElement);
};
