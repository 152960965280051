import * as React from 'react';
const MediaSkeleton = () => {
    return <article role="status" className="max-w-sm rounded-lg shadow animate-pulse">
        <div className="relative w-full flex items-center justify-center h-36 mb-4 bg-gray-300 rounded-t-lg">
            <svg className="w-8 h-8 text-gray-200" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" viewBox="0 0 384 512">
                <path d="M361 215C375.3 223.8 384 239.3 384 256C384 272.7 375.3 288.2 361 296.1L73.03 472.1C58.21 482 39.66 482.4 24.52 473.9C9.377 465.4 0 449.4 0 432V80C0 62.64 9.377 46.63 24.52 38.13C39.66 29.64 58.21 29.99 73.03 39.04L361 215z"/>
            </svg>
        </div>
        <div className="flex items-center mt-4 space-x-3">
            <div className="px-2 py-4">
                <div className="h-2 bg-gray-200 rounded-full mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full mb-2.5"></div>
                <div className="h-2.5 bg-gray-200 rounded-full w-32 mb-2"></div>
                <div className="w-48 h-2 bg-gray-200 rounded-full"></div>
            </div>
        </div>
    </article>;
};
MediaSkeleton.displayName = 'MediaSkeleton';
export default MediaSkeleton;
