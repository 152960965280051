import * as React from 'react';
import ReactModal from 'react-modal';
import { useState } from 'react';
import parse from 'html-react-parser';
import Confetti from 'react-confetti';
import BaseElement from '../../CustomElements/BaseElement';
const customStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        zIndex: 99999,
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: '0',
        width: '100%',
        maxWidth: '750px',
        minHeight: '350px',
        maxHeight: '100%',
    }
};
const VideoChallengeModal = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalDetail, setModalDetail] = useState({
        title: '',
        description: '',
        buttonTitle: '',
        buttonLink: '',
    });
    const handleModal = () => {
        setModalIsOpen(!modalIsOpen);
    };
    document.addEventListener('open-challenge-modal-event', (event) => {
        setModalDetail({
            title: event.detail?.title || modalDetail.title,
            description: event.detail?.description || modalDetail.description,
            buttonTitle: event.detail?.buttonTitle || modalDetail.buttonTitle,
            buttonLink: event.detail?.buttonLink || modalDetail.buttonLink,
        });
        handleModal();
    });
    return <>
        {modalIsOpen && <Confetti width={window.innerWidth} height={window.innerHeight} gravity={0.05}/>}
        <ReactModal isOpen={modalIsOpen} shouldCloseOnOverlayClick={false} contentLabel={'Modal'} style={customStyles}>
            <section className="p-8">
                <header className="flex flex-row">
                    <div className="mx-auto flex h-24 w-24 items-center justify-center rounded-full">
                        <img alt="Sitzkrieger.com" src="/static/santa_jumpin.png"/>
                    </div>
                </header>
                <main>
                    <div className="mt-5 sm:mt-6 text-center sm:mt-5">
                        <h2 className="text-xl font-semibold leading-6 text-gray-900" id="modal-title">
                            {modalDetail.title}
                        </h2>
                        <div className="prose mt-5 sm:mt-6">
                            <p className="text-base text-gray-900">
                                {parse(modalDetail.description)}
                            </p>
                        </div>
                    </div>
                </main>
                <footer>
                    <div className="mt-5 sm:mt-6">
                        <a href={modalDetail.buttonLink} className="inline-flex w-full flex text-lg font-bold shadow-md items-center justify-center p-4 py-2 my-2 border border-transparent bg-red-500 hover:bg-red-600 rounded-full text-white m-auto">
                            {modalDetail.buttonTitle}
                        </a>
                    </div>
                </footer>
            </section>
        </ReactModal>
    </>;
};
VideoChallengeModal.displayName = 'VideoChallengeModal';
export default VideoChallengeModal;
class VideoChallengeModalElement extends BaseElement {
    constructor() {
        super(...arguments);
        this.element = VideoChallengeModal;
    }
}
export const loadVideoChallengeModalElement = () => {
    customElements.define('video-challenge-modal', VideoChallengeModalElement);
};
