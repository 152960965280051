import * as React from 'react';
import ReactModal from 'react-modal';
import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import parse from 'html-react-parser';
const customStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        zIndex: 99999,
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: '0',
        width: '100%',
        maxWidth: '650px',
        minHeight: '350px',
        maxHeight: '100%',
    }
};
const config = {
    cookieName: 'privacy-consent',
    cookieExpires: 14,
    texts: {
        header: 'Privatsphäre-Einstellungen',
        text: 'Wir verwenden Cookies und ähnliche Technologien auf unserer Website und verarbeiten personenbezogene Daten von dir (z.B. IP-Adresse), um z.B. Inhalte zu personalisieren, Medien von Drittanbietern einzubinden oder Zugriffe auf unsere Website zu analysieren. Die Datenverarbeitung kann auch erst in Folge gesetzter Cookies stattfinden. Das Senden von Daten an Dritte, u.a. YouTube, erfordert eine separate Zustimmung. Weitere Informationen findest du in der <a href="/datenschutz" target="_blank">Datenschutzerklärung</a>.',
        buttonConfirm: 'Verstanden'
    },
};
const CookieConsentModal = () => {
    const [modalIsOpen, setIsOpen] = useState(false);
    useEffect(() => {
        if (getCookieConsentValue() === undefined) {
            setIsOpen(true);
        }
    }, [modalIsOpen]);
    function getCookieConsentValue() {
        return Cookies.get(config.cookieName);
    }
    function accept() {
        setCookie();
        setIsOpen(false);
    }
    function setCookie() {
        const cookieOptions = {
            expires: config.cookieExpires
        };
        Cookies.set(config.cookieName, '1', cookieOptions);
    }
    return <ReactModal isOpen={modalIsOpen} shouldCloseOnOverlayClick={false} contentLabel={'Modal'} style={customStyles}>
        <section className="p-8">
            <header className="flex flex-row">
                <div className="mx-auto flex h-14 w-14 items-center justify-center rounded-full">
                    <img alt="Sitzkrieger.com" src="/static/Logo_Head.png"/>
                </div>
            </header>
            <main>
                <div className="mt-5 sm:mt-6 text-center sm:mt-5">
                    <h2 className="text-xl font-semibold leading-6 text-gray-900" id="modal-title">
                        {config.texts.header}
                    </h2>
                    <div className="prose mt-5 sm:mt-6">
                        <p className="text-sm text-gray-500">
                            {parse(config.texts.text)}
                        </p>
                    </div>
                </div>
            </main>
            <footer>
                <div className="mt-5 sm:mt-6">
                    <button type="button" className="inline-flex w-full flex text-lg font-bold shadow-md items-center justify-center p-4 py-2 my-2 border border-transparent bg-brand-primary rounded-full text-white hover:opacity-80 m-auto" onClick={() => accept()}>
                        {config.texts.buttonConfirm}
                    </button>
                </div>
                <div className="flex flex-row-reverse text-sm text-gray-500">
                    <a href="/impressum" target="_blank">Impressum</a>
                </div>
            </footer>
        </section>
    </ReactModal>;
};
CookieConsentModal.displayName = 'CookieConsentModal';
export default CookieConsentModal;
