import * as React from 'react';
import { interactWithFavouritePlaylist, generateUrl } from '../../../Utilities/api';
import { toast } from 'react-hot-toast';
import PermissionDeniedEvent from '../../../Events/PermissionDeniedEvent';
import IconSet from './IconSet';
export const FavouriteButton = ({ inFavourites, videoId }) => {
    const [isInFavourites, setIsInFavourites] = React.useState(inFavourites);
    return <div title={isInFavourites ? 'Aus Favoriten entfernen' : 'Zu Favoriten hinzufügen'} className={'relative z-10 rounded-b-md bg-white text-black px-1 py-1 text-xs font-normal ' + (isInFavourites ? 'text-brand-red0' : '') + ' hover:text-brand-red0 z-50'}>
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 576 512" fill="currentColor" onClick={(event) => {
            event.preventDefault();
            interactWithFavouritePlaylist(videoId, isInFavourites ? 'remove' : 'add').then((json) => {
                if (json.success) {
                    setIsInFavourites(!isInFavourites);
                    toast.success(isInFavourites ? 'Erfolgreich aus den Favoriten entfernt' : <div>Erfolgreich zu den Favoriten hinzugefügt <div className="mt-3 flex justify-end"><a className="text-brand-primary" href={generateUrl('sitzkrieger_playlist_detail', { 'identifier': 'favourite' })}>Zu meinem Bereich</a></div></div>);
                }
                else {
                    if (json?.status === 401) {
                        toast.error('Du must eingeloggt sein, um Videos zu den Favoriten hinzufügen zu können.');
                    }
                    else {
                        toast.error('Es ist ein Fehler aufgetreten');
                    }
                }
            });
        }}>
            <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
        </svg>
    </div>;
};
const MediaElement = ({ video, additionalClassName, mediaElementClickCallback, lockWithPreview, forVideoSeries, }) => {
    const date = video.data.scheduledTime || video.creationDate;
    const isNew = (((new Date()).getTime() - (new Date(date)).getTime())) < (30 * 24 * 60 * 60 * 1000);
    const link = video.isLocked || typeof mediaElementClickCallback === 'function' ? 'javascript:void(0)' : '/w/' + video.id;
    return <a href={link} role={video.isLocked ? 'button' : undefined} onClick={video.isLocked ? (e) => {
            e.preventDefault();
            if (forVideoSeries) {
                PermissionDeniedEvent({
                    title: 'Deine Videoserie',
                    description: 'Du musst erst das letzte Video anschauen, damit du das nächste Video freischalten kannst',
                    showFooter: false,
                });
            }
            else {
                PermissionDeniedEvent();
            }
        } : (typeof mediaElementClickCallback === 'function' ? () => mediaElementClickCallback(Number(video.id)) : undefined)} title={video.title} className={'flex flex-col items-start justify-start bg-gray-100 rounded-lg drop-shadow-md hover:shadow-lg max-w-xs mb-12 mx-2 lg:mx-4 ' + additionalClassName || ''} 
    // disabling this for now, might add back in the future style={{backgroundColor: video.videoBoxColor, color: video.videoTextColor}}>
    style={{ backgroundColor: '#f4f4f4', color: '#000000' }}>
        <div className="relative group">
            <div className='absolute top-0 flex justify-end px-4 w-full'>
                {video.isLocked ? <></> : <FavouriteButton inFavourites={video.inFavourites || false} videoId={Number(video.id)}/>}
            </div>
            <img loading={'lazy'} src={video.data.thumbnail} className={'min-h-[180px] w-full rounded-t-lg'}/>

            <div className={'absolute top-0 flex items-center align-center justify-center w-full h-full rounded-t-lg ' + (video.isLocked ? 'bg-black/60' : '')}>
                {video.isLocked ? <>
                    <i className={'fa fa-lock text-white text-2xl ' + (lockWithPreview ? 'z-10' : '')}/>
                </> : <>
                    {video.data.type === 'youtube' || video.data.type === 'video' ?
                <i className="fa fa-play text-white text-2xl"/> : ''}
                </>}
            </div>

            <div className="absolute bottom-0 flex justify-between px-2 pb-2 w-full">
                <span></span>
                {video.durationString != ''
            ? <span className="relative z-10 rounded-md bg-gray-700 text-white px-1 py-0.5 text-xs font-normal">{video.durationString}</span>
            : <span></span>}
            </div>
        </div>
        <div className="w-full px-4 pb-4">
            <div className="mt-4 flex justify-between items-center">
                <div className="flex items-center min-h-[24px]">
                    <IconSet icons={video.chapterIcons}></IconSet>
                </div>
                {!video.isLocked && !forVideoSeries && isNew ?
            <span className="inline-flex items-center rounded-md bg-green-100 px-1.5 py-0.5 text-xs font-bold text-green-700">NEU</span>
            : <></>}
            </div>
            <div className="mt-4 group relative">
                <div className="text-lg font-semibold leading-6">
                    {video.title}{video.data.type === 'broadcast' ? <>
                    &nbsp;- vom {(new Date(video.data.scheduledTime || '')).toLocaleDateString('de', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
            })}
                    </> : <></>}
                </div>
            </div>
        </div>
    </a>;
};
MediaElement.displayName = 'MediaElement';
export default MediaElement;
