import React, { useEffect } from 'react';
import Select, { components } from 'react-select';
const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted black',
        color: state.getValue()[0] === state.data ? 'white' : 'black',
        backgroundColor: state.getValue()[0] === state.data ? '#00537e' : 'white',
    }),
    input: (css) => ({
        ...css,
        input: {
            outline: 'none',
        },
        'input:focus': {
            boxShadow: 'none',
        },
        color: 'white',
    }),
    control: (css) => ({
        ...css,
        borderRadius: '9999px',
        backgroundColor: '#00537e',
        color: 'white',
        borderColor: '#00537e'
    }),
    singleValue: (css) => ({
        ...css,
        color: 'white',
    }),
    placeholder: (css) => ({
        ...css,
        color: 'white',
    }),
};
const Input = (props) => (<components.Input {...props} inputClassName="outline-none border-none shadow-none focus:ring-transparent"/>);
const InsuranceDiscountSearch = (insurances) => {
    const [discount, setDiscount] = React.useState(0.0);
    useEffect(() => {
        const priceNode = insurances.parentNode.querySelector('.js-praeventionskurse-price');
        if (priceNode) {
            const price = Number(priceNode.dataset?.price);
            // priceNode.innerText = String();
            priceNode.innerText = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2 })
                .format((price - (price * discount)));
        }
    }, [discount]);
    return (<>
        <Select styles={customStyles} options={Object.keys(insurances.discounts).map((insurance) => {
            return { label: insurance, value: insurances.discounts[insurance] };
            // @ts-ignore
        })} components={{ Input }} placeholder={'Krankenkasse auswählen'} onChange={(val) => setDiscount(val.value)}/>
        <div className="mb-16"></div>
        <div className="w-full px-4 py-2 border border-transparent rounded-full text-white bg-brand-primary hover:opacity-80">Erstattung: <span className="js-praeventionskurse-discount">{(discount * 100)}</span>%
        </div>
    </>);
};
InsuranceDiscountSearch.displayName = 'InsuranceDiscountSearch';
export default InsuranceDiscountSearch;
