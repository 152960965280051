import * as React from 'react';
import { useEffect, useState } from 'react';
import { generateUrl, getChallenge, videoStatisticsFinish, videoStatisticsStart, videoStatisticsWatch } from '../../../Utilities/api';
import { toast } from 'react-hot-toast';
import VideoPlayer from '../VideoPlayer';
import VideoChallengeGrid from './VideoChallengeGrid';
import VideoChallengeProgressBar from './VideoChallengeProgressBar';
import OpenChallengeModalEvent from '../../../Events/OpenChallengeModalEvent';
import { UserContext } from '../../Context/UserContext';
import { useI18n } from '../../../Translation';
import MediaElementDetails from '../Mediathek/MediaElementDetails';
import MediaElementDownloads from '../Mediathek/MediaElementDownloads';
const Challenge = (props) => {
    const [videoChallenge, setVideoChallenge] = useState(null);
    const [currentVideo, setCurrentVideo] = useState(null);
    const [userVideoId, setUserVideoId] = useState('');
    const [lastVideoId, setLastVideoId] = useState('');
    const user = React.useContext(UserContext);
    const i18n = useI18n();
    useEffect(() => {
        loadVideoChallenge();
    }, []);
    const loadVideoChallenge = () => {
        getChallenge(props.videoSeriesId).then((res) => {
            if (res.success === false) {
                toast.error(res.message);
            }
            else {
                setVideoChallenge(res);
                setCurrentVideo(res.currentVideo);
                setLastVideoId(res.lastVideoId);
                if (res.lastVideoId === res.currentVideo.id
                    && res.currentVideo.watched
                    && user !== null
                    && user.properties['adventskalender_modal_interacted'] !== true) {
                    const url = generateUrl('challenge_finish_modal', {
                        id: props.videoSeriesId,
                    });
                    if ((user.company || '') !== '') {
                        OpenChallengeModalEvent({
                            title: i18n['challenge.modal.merckUser.title'],
                            description: i18n['challenge.modal.merckUser.description'],
                            buttonTitle: i18n['challenge.modal.merckUser.buttonTitle'],
                            buttonLink: url,
                        });
                    }
                    else {
                        OpenChallengeModalEvent({
                            title: i18n['challenge.modal.normalUser.title'],
                            description: i18n['challenge.modal.normalUser.description'],
                            buttonTitle: i18n['challenge.modal.normalUser.buttonTitle'],
                            buttonLink: url,
                        });
                    }
                }
            }
        });
    };
    const handleClick = () => {
        const videoPlayerContainer = document.getElementById('video-player-container');
        if (videoPlayerContainer) {
            videoPlayerContainer.scrollIntoView({
                behavior: 'smooth'
            });
        }
    };
    return <>
        {videoChallenge !== null && currentVideo !== null ? <section className="mx-auto py-5">
            <div id="video-player-container" className="grid grid-cols-1 gap-8 sm:gap-6 lg:col-span-2">
                {currentVideo.isLocked ? <></> : <>
                    <VideoChallengeProgressBar progress={videoChallenge.progress}/>

                    <div className="relative w-full rounded-lg aspect-video">
                        <VideoPlayer {...currentVideo} id={Number(currentVideo.id)} controls={true} playing={true} muted={false} responsive={false} preview={true} fullscreen={true} height={'100%'} withMargin={false} thumbnailWidth="1080" redirectToOnFinished={() => { loadVideoChallenge(); }} onProgress={(stats) => {
                    if (userVideoId === '') {
                        return;
                    }
                    if (stats.playedSeconds > 3 && ((Math.round(stats.playedSeconds) % 5 === 0 && stats.played < 0.98))) {
                        videoStatisticsWatch(userVideoId, Number(currentVideo.id), stats.playedSeconds, props.videoSeriesId);
                    }
                }} onStart={() => {
                    videoStatisticsStart(Number(currentVideo.id), props.videoSeriesId).then((v) => setUserVideoId(v));
                }} onFinish={() => {
                    if (userVideoId === '') {
                        return;
                    }
                    videoStatisticsFinish(userVideoId, Number(currentVideo.id), props.videoSeriesId);
                    if (user && Number(currentVideo.id) === Number(lastVideoId) && user.properties['adventskalender_modal_interacted'] !== true) {
                        const url = generateUrl('challenge_finish_modal', {
                            id: props.videoSeriesId,
                        });
                        if ((user.company || '') !== '') {
                            OpenChallengeModalEvent({
                                title: i18n['challenge.modal.merckUser.title'],
                                description: i18n['challenge.modal.merckUser.description'],
                                buttonTitle: i18n['challenge.modal.merckUser.buttonTitle'],
                                buttonLink: url,
                            });
                        }
                        else {
                            OpenChallengeModalEvent({
                                title: i18n['challenge.modal.normalUser.title'],
                                description: i18n['challenge.modal.normalUser.description'],
                                buttonTitle: i18n['challenge.modal.normalUser.buttonTitle'],
                                buttonLink: url,
                            });
                        }
                    }
                }}/>
                    </div>
                    <MediaElementDetails video={currentVideo}/>
                    <MediaElementDownloads video={currentVideo}/>
                </>}

                <div className="lg:hidden w-full border-t border-gray-100"/>

                <div className="rounded-lg bg-gray-100 shadow p-6">
                    <VideoChallengeGrid videos={videoChallenge.videos} videoChallenge={videoChallenge} mediaElementClickCallback={(videoId) => {
                videoChallenge.videos.forEach((el) => {
                    if (Number(el.id) === Number(videoId)) {
                        setCurrentVideo(el);
                        handleClick();
                    }
                });
            }}/>
                </div>
            </div>
        </section> : ''}
    </>;
};
Challenge.displayName = 'Challenge';
export default Challenge;
