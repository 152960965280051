'use strict';
class Accordion extends HTMLElement {
    constructor() {
        super();
        this.items = [];
    }
    connectedCallback() {
        this.items = [];
        const onBeforeOpen = () => {
            this.items.forEach((accordion) => accordion.close());
        };
        this.querySelectorAll('.accordion__item').forEach((item) => {
            this.items.push(new AccordionItem(item, onBeforeOpen.bind(this)));
        });
    }
}
class AccordionItem {
    constructor(domNode, onBeforeOpen) {
        this.rootElement = domNode;
        this.onBeforeOpen = onBeforeOpen;
        this.buttonElement = this.rootElement.querySelector('button.accordion__item__button');
        const controlsElement = this.buttonElement?.getAttribute('aria-controls');
        this.icon = null;
        this.open = false;
        this.panelElement = null;
        if (this.buttonElement) {
            this.panelElement = this.rootElement.querySelector(`#${controlsElement}`);
            this.icon = this.rootElement.querySelector(`#${this.buttonElement.id}-icon`);
            this.open = this.buttonElement.getAttribute('aria-expanded') === 'true';
            this.buttonElement.addEventListener('click', this.onButtonClick.bind(this));
            this.icon?.addEventListener('click', this.onButtonClick.bind(this));
        }
    }
    onButtonClick() {
        this.toggle(!this.open);
    }
    toggle(open) {
        if (open === this.open) {
            return;
        }
        if (open) {
            this.onBeforeOpen();
        }
        this.open = open;
        if (this.icon) {
            this.icon.style.transform = open ? 'rotate(180deg)' : '';
        }
        if (this.buttonElement) {
            this.buttonElement.setAttribute('aria-expanded', `${open}`);
        }
        if (this.panelElement) {
            open ?
                this.panelElement.removeAttribute('invisible') :
                this.panelElement.setAttribute('invisible', '');
        }
    }
    close() {
        this.toggle(false);
    }
}
export default () => {
    customElements.define('sitzkrieger-accordion', Accordion);
};
