import * as React from 'react';
import MediaElement from './MediaElement';
import CustomSlider from './CustomSlider';
const VideoCategoryList = ({ videos, categories, categoriesSorting, productAsSlider, sortByDate, disableProductSliderTitle, mediaElementClickCallback, lockWithPreview, forVideoSeries, disableInfinity, }) => {
    const elements = [];
    let sortedVideos = videos;
    if (sortByDate) {
        sortedVideos = videos.sort((a, b) => {
            return (new Date(b.data.scheduledTime ?? b.creationDate)).getTime() - (new Date(a.data.scheduledTime ?? a.creationDate)).getTime();
        });
    }
    categories.sort((a, b) => {
        return categoriesSorting.indexOf(a) > categoriesSorting.indexOf(b) ? 1 : -1;
    });
    for (const category of categories) {
        const videoElements = [];
        const dates = [];
        let description = '';
        let sorting = 0;
        for (const video of sortedVideos) {
            if (video.hasOwnProperty('categories') && video.categories.map((cat) => cat.title).includes(category)) {
                if (sorting === 0) {
                    video.categories.forEach((cat) => {
                        if (cat.title === category && cat.sorting_value !== 0) {
                            sorting = cat.sorting_value || 0;
                            description = cat.description || '';
                        }
                    });
                }
                if (sorting === 2) {
                    dates.push((new Date(video.data.scheduledTime ?? video.creationDate)).getTime());
                }
                videoElements.push(<MediaElement key={video.id} video={video}/>);
            }
        }
        let sortedVideoElements = videoElements;
        switch (sorting) {
            case 1:
                sortedVideoElements = videoElements.map((a) => ({ sort: Math.random(), value: a }))
                    .sort((a, b) => a.sort - b.sort)
                    .map((a) => a.value);
                break;
            case 2:
                console.error('not supported yet');
                // sortedVideoElements = videoElements.sort((a, b) => {
                //   return categoriesSorting.indexOf(a) > categoriesSorting.indexOf(b) ? 1 : -1;
                // });
                break;
            default:
                break;
        }
        elements.push({ title: category, videos: sortedVideoElements, description: description });
    }
    if (!elements.length && (productAsSlider || forVideoSeries)) {
        const videoElements = [];
        for (const video of sortedVideos) {
            videoElements.push(<MediaElement key={video.id} video={video} mediaElementClickCallback={mediaElementClickCallback} lockWithPreview={lockWithPreview} forVideoSeries={forVideoSeries}/>);
        }
        elements.push({ title: forVideoSeries ? '' : productAsSlider, videos: videoElements, description: '' });
    }
    if (videos.length === 0) {
        return <></>;
    }
    return <section className="my-4">
        {elements.map(({ title, videos, description }) => <article className="my-10" key={title}>
            {disableProductSliderTitle ? '' : <header className="my-4">
                <h1 className="font-bold text-3xl mb-2 text-brand-primary">{title}</h1>
                {description !== '' ? <h2 className="font-bold text-1xl text-brand-darkgray mb-2">{description}</h2> : <></>}
            </header>}
            <CustomSlider slidesXl={4} slidesLg={3} slidesMd={2} slidesSm={1} infinity={!disableInfinity} count={videos.length}>
                {videos}
            </CustomSlider>
        </article>)}
    </section>;
};
VideoCategoryList.displayName = 'VideoCategoryList';
export default VideoCategoryList;
