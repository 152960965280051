const dispatchOpenChallengeModalEvent = (detail = {}) => {
    const OpenChallengeModalEvent = new CustomEvent('open-challenge-modal-event', {
        cancelable: true,
        bubbles: true,
        detail,
        composed: false
    });
    document.dispatchEvent(OpenChallengeModalEvent);
};
export default dispatchOpenChallengeModalEvent;
