const dispatchPermissionDeniedEvent = (detail = {}) => {
    const PermissionDeniedEvent = new CustomEvent('permission-denied-event', {
        cancelable: true,
        bubbles: true,
        detail,
        composed: false
    });
    document.dispatchEvent(PermissionDeniedEvent);
};
export default dispatchPermissionDeniedEvent;
