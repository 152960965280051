import * as React from 'react';
import { Disclosure, Transition } from '@headlessui/react';
import parse from 'html-react-parser';
export const Accordion = ({ label, content }) => {
    return <Disclosure>
        {({ open }) => (<>
                <Disclosure.Button className="py-2 w-full">
                    <div className="mb-2 relative">
                        <div className="absolute inset-0 flex items-center" aria-hidden="true">
                            <div className="w-full border-t border-gray-300"></div>
                        </div>
                        <div className="my-4 relative flex justify-center">
                            <div className="inline-flex items-center gap-x-1.5 rounded-full bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                                {open
                ? <i className="fas fa-minus"></i>
                : <i className="fas fa-plus"></i>}
                                {label}
                            </div>
                        </div>
                    </div>
                </Disclosure.Button>
                <Transition enter="transition duration-100 ease-out" enterFrom="transform scale-95 opacity-0" enterTo="transform scale-100 opacity-100" leave="transition duration-75 ease-out" leaveFrom="transform scale-100 opacity-100" leaveTo="transform scale-95 opacity-0">
                    <Disclosure.Panel>
                        {parse(content)}
                    </Disclosure.Panel>
                </Transition>
            </>)}
    </Disclosure>;
};
Accordion.displayName = 'Accordion';
export default Accordion;
