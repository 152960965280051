import * as React from 'react';
import ProfileStatsTileWatchTime from './Partials/ProfileStatsTileWatchTime';
import ProfileStatsTileActiveDays from './Partials/ProfileStatsTileActiveDays';
import ProfileStatsTileWatchedVideos from './Partials/ProfileStatsTileWatchedVideos';
const ProfileStats = () => {
    return <div>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
            <ProfileStatsTileWatchTime label={'Trainingsminuten'}/>
            <ProfileStatsTileWatchedVideos label={'absolvierte Einheiten'}/>
            <ProfileStatsTileActiveDays label={'Aktive Tage'}/>
        </dl>
    </div>;
};
ProfileStats.displayName = 'ProfileStats';
export default ProfileStats;
