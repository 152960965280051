import * as React from 'react';
import { useState } from 'react';
import Modal from './Modal';
const PermissionDeniedModal = ({ loginUrl, moreInfoUrl, registerUrl }) => {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalDetail, setModalDetail] = useState({
        title: 'Hoppla, da ist etwas schief gelaufen!',
        description: 'Um die Inhalte anzusehen, logge dich bitte ein oder erstelle einen kostenlosen Account',
        loginUrl: loginUrl,
        moreInfoUrl: moreInfoUrl,
        registerUrl: registerUrl,
        showFooter: true,
    });
    document.addEventListener('permission-denied-event', (event) => {
        setModalDetail({
            title: event.detail?.title || modalDetail.title,
            description: event.detail?.description || modalDetail.description,
            loginUrl: modalDetail.loginUrl,
            moreInfoUrl: modalDetail.moreInfoUrl,
            registerUrl: modalDetail.registerUrl,
            showFooter: event.detail?.showFooter === false ? false : modalDetail.showFooter,
        });
        openModal();
    });
    function openModal() {
        setIsOpen(true);
    }
    function closeModal() {
        setIsOpen(false);
    }
    return <Modal modalOpen={modalIsOpen} closeModal={closeModal} modalHeader={modalDetail.title} footer={modalDetail.showFooter ? <>
            <a className='w-1/2 max-w-max flex text-lg font-bold items-center justify-center p-4 py-2 my-2 border border-transparent  bg-brand-primary rounded-full text-white hover:opacity-80' href={modalDetail.loginUrl}>Einloggen</a>
            {
            // <a className='w-1/2 max-w-max flex text-lg font-bold items-center justify-center p-4 py-2 my-2 border border-transparent  bg-brand-primary rounded-full text-white hover:opacity-80' href={modalDetail.moreInfoUrl}>Mehr Erfahren</a>
            <a className='w-1/2 max-w-max flex text-lg font-bold items-center justify-center p-4 py-2 my-2 border border-transparent  bg-brand-primary rounded-full text-white hover:opacity-80' href={modalDetail.registerUrl}>Registrieren</a>}
        </> : <></>}>
        <p className='mt-3'>{modalDetail.description}</p>
    </Modal>;
};
PermissionDeniedModal.displayName = 'PermissionDeniedModal';
export default PermissionDeniedModal;
