import React, { useState } from 'react';
import { RgbColorPicker, HexColorInput } from 'react-colorful';
import { colord } from 'colord';
import { setCompanyColor } from '../../Utilities/api';
import { useDebouncyEffect } from 'use-debouncy';
const getRgbFromProperty = (property) => {
    const rgb = getComputedStyle(document.documentElement)
        .getPropertyValue(`--color-${property}`)
        .split(',')
        .map((value) => Number(value));
    if (rgb.length === 3) {
        return { r: rgb[0], g: rgb[1], b: rgb[2] };
    }
    return { r: 0, g: 0, b: 0 };
};
const CompanyColorChanger = ({ type, label }) => {
    const [color, setColor] = useState(getRgbFromProperty(type));
    const onColorChange = (newColor) => {
        setColor(newColor);
        document.documentElement.style.setProperty(`--color-${type}`, `${newColor.r}, ${newColor.g}, ${newColor.b}`);
    };
    useDebouncyEffect(() => {
        setCompanyColor(type, color).then();
    }, 200, [color]);
    return <div>
        <h5 className="font-bold text-lg">{label}</h5>
        <React.StrictMode>
            <RgbColorPicker color={color} onChange={(newColor) => onColorChange(newColor)}/>
            <HexColorInput className="w-[200px] form-input mt-1 mb-2 focus:ring-brand-primary focus:border-brand-primary block w-full py-2 px-4 border shadow-sm sm:text-sm border-gray-300 rounded-full" color={colord(color).toHex()} onChange={(hex) => onColorChange(colord(hex).toRgb())}/>
        </React.StrictMode>
    </div>;
};
CompanyColorChanger.displayName = 'CompanyColorChanger';
export default CompanyColorChanger;
