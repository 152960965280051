import * as React from 'react';
const TherabandIcon = () => {
    return <>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 992.54 992.54" fill="currentColor">
            <g id="BG">
                <g id="Pfad_590" data-name="Pfad 590">
                    <path d="m885.88,555.37c-5.95-4.23-13.71-7.78-23.18-10.58-36.28-10.72-93.66-9.75-170.55,2.9-70.97,11.67-174.55,35.95-307.86,72.16-18.78,5.1-38.45,9.36-58.45,12.65-22.46,3.69-45.74,6.24-69.2,7.57-18.77,1.07-37.7,1.36-56.26.87-24.04-.63-41.26-2.43-48.21-3.27l-30.89-187.82c7.28,1.1,21.38,2.56,44.77,2.48,33.45-.11,93.33-3.54,188.65-19.22,93.79-15.43,221.88-42.72,312.82-62.9,7.69-1.71,15.59-3.23,23.48-4.53,21.13-3.47,43.05-5.49,65.17-5.98,17.71-.4,35.58.17,53.11,1.7,22.87,1.98,39.24,5.1,45.75,6.47l30.84,187.49Z"/>
                    <path d="m126.75,455.87l29.1,176.92c8.33.91,23.9,2.31,44.51,2.84,18.44.48,37.25.18,55.9-.88,23.29-1.33,46.41-3.86,68.71-7.53,19.88-3.27,39.42-7.5,58.09-12.57,133.44-36.24,237.14-60.55,308.23-72.24,77.45-12.74,135.4-13.68,172.24-2.79,6.49,1.92,11.88,4.09,16.34,6.35l-28.54-173.55c-7.74-1.52-22.38-4.03-41.89-5.73-17.42-1.52-35.17-2.09-52.77-1.7-21.99.49-43.78,2.48-64.78,5.94-7.83,1.29-15.66,2.8-23.29,4.49-132.34,29.37-237.65,50.54-313.02,62.93-95.56,15.72-155.65,19.15-189.24,19.27-18.46.06-31.29-.84-39.59-1.78m-10.99-12.37s54.01,14.64,238.08-15.64c90.38-14.86,212.11-40.56,312.62-62.86,7.99-1.77,15.91-3.29,23.68-4.57,93.53-15.39,168.63,2.93,168.63,2.93l33.93,206.27s-15.62-46.96-199.69-16.69c-83.94,13.8-202.9,43.67-307.5,72.08-124.77,33.89-237.03,17.48-237.03,17.48l-32.73-199.01Z"/>
                </g>
            </g>
            <g id="Layer_1" data-name="Layer 1">
                <path stroke="#000" strokeMiterlimit="10" strokeWidth="7px" d="m784.86,495.38c-22.64.52-47.24,2.01-73.46,4.94-58.69,6.56-108.94,18.5-149.23,30.82"/>
            </g>
        </svg>
    </>;
};
TherabandIcon.displayName = 'TherabandIcon';
export default TherabandIcon;
