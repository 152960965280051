import * as React from 'react';
import { useEffect, useState } from 'react';
import CloseIcon from './CloseIcon';
import ConfiguratorTab from './ConfiguratorTab';
import { getMediaElement, getPostcards, getQuestions, getSingleGuide } from '../../../Utilities/api';
import ReactModal from 'react-modal';
import PlayIcon from './PlayIcon';
import parse from 'html-react-parser';
import PermissionDeniedEvent from '../../../Events/PermissionDeniedEvent';
import { FavouriteButton } from '../Mediathek/MediaElement';
const customStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        zIndex: 99999,
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: '0',
        width: '100%',
        maxWidth: '650px',
        minHeight: '350px',
        maxHeight: '100%',
        border: '',
        borderRadius: '30px',
    }
};
const defaultQuestions = [{
        'question': 'Lädt...',
        'answers': []
    }];
const defaultLabels = {
    title: 'Konfigurator',
    training: {
        availableTrainingPlans: 'Wir haben folgenden Trainingsplan für dich',
        downloadLabel: 'Lade dir den Trainingsplan zum Video hier herunter!',
        downloads: 'Trainingsplan zum Download',
        relatedVideos: 'Passende Videos für dich',
    },
    exercises: {
        availableExercises: 'Wir haben folgende Übung für dich',
        downloads: 'Übungen zum Download',
    },
};
const ConfiguratorModal = ({ labels, questionId = null, clickAttribute, useClassInsteadOfAttribute = false, showEndcardText = false, modalLayout = 'workout', modalBackgroundImage = '', isGranted = false, }) => {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [questions, setQuestions] = useState(defaultQuestions);
    const [question, setQuestion] = useState(questions[0]);
    const [selectedAnswer, setSelectedAnswer] = useState(null);
    const [endOfQuestions, setEndOfQuestions] = useState(false);
    const [postCards, setPostCards] = useState([]);
    const [categoryNumber, setCategoryNumber] = useState(0);
    const [postcardTitle, setPostcardTitle] = useState('');
    let trainingLabels = Object.assign({}, defaultLabels.training);
    trainingLabels = Object.assign(trainingLabels, labels?.training || {});
    let exercisesLabels = Object.assign({}, defaultLabels.exercises);
    exercisesLabels = Object.assign(exercisesLabels, labels?.exercises || {});
    let selector = `[data-attribute='${clickAttribute}']`;
    if (useClassInsteadOfAttribute) {
        selector = '.' + clickAttribute;
    }
    document.querySelectorAll(selector).forEach((element) => {
        element.addEventListener('click', openModal);
    });
    let headerClass = 'bg-brand-blue1';
    switch (modalLayout) {
        case 'active':
            headerClass = 'bg-brand-lightblue1';
            break;
        case 'ergonomie':
            headerClass = 'bg-brand-yellow1';
            break;
        case 'pain':
            headerClass = 'bg-brand-red1';
            break;
        case 'nutrition':
            headerClass = 'bg-brand-green1';
            break;
    }
    function openModal(event) {
        event.preventDefault();
        if (isGranted) {
            setIsOpen(true);
        }
        else {
            PermissionDeniedEvent();
        }
    }
    function closeModal() {
        resetConfigurator();
        setIsOpen(false);
    }
    const nextQuestion = (answerProp) => {
        if (answerProp !== null) {
            if (answerProp.next === 'send') {
                setEndOfQuestions(true);
                setSelectedAnswer(answerProp);
                setQuestion(questions[1]);
                setCategoryNumber(answerProp.categoryNumber);
                getPostcards(answerProp.categoryNumber).then((postCardList) => {
                    setPostCards(postCardList);
                });
            }
            else {
                setQuestion(questions[answerProp.next]);
            }
        }
    };
    useEffect(() => {
        getQuestions(questionId || null).then(apiQuestions => {
            setQuestions(apiQuestions);
            setQuestion(apiQuestions[0]);
        });
    }, []);
    const resetConfigurator = () => {
        setEndOfQuestions(false);
        setQuestion(questions[0]);
        setCategoryNumber(0);
    };
    const downloadExercise = async (type) => {
        const guide = await getSingleGuide(type);
        if (guide.id) {
            const pdf = getMediaElement(guide.id, 'g');
            // @ts-ignore
            window.open(pdf, '_blank').focus();
        }
    };
    return <div>
        <ReactModal isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel={'Configurator'} style={customStyles}>
            <section className="sitzkrieger-collection__modal">
                <header className={`sitzkrieger-collection__header ${headerClass} relative`}>
                    {modalBackgroundImage !== '' ? parse(modalBackgroundImage) : ''}
                    <div className={'relative z-10'}>
                        {endOfQuestions ? (postCards.length > 0 ? <>
                            {postCards[0].type === '1' ? exercisesLabels.availableExercises : trainingLabels.availableTrainingPlans}<br />
                            {postcardTitle}
                        </> : '') : question.question}
                    </div>
                </header>
                <button className="sitzkrieger-collection__button--close" aria-label="Close" type="button" onClick={closeModal}>
                    <CloseIcon /></button>
                <div className="sitzkrieger-collection__body">
                    {!endOfQuestions ? (<>
                            <div className="sitzkrieger-collection__body__content">
                                {question.answers.map((answer) => {
                if (answer.color) {
                    return <button className="sitzkrieger-collection__button sitzkrieger-collection__button--withSpace" key={answer.answer} onClick={() => nextQuestion(answer)} style={{ backgroundColor: answer.color }}>
                                            {answer.answer}
                                        </button>;
                }
                return <button className="sitzkrieger-collection__button sitzkrieger-collection__button--withSpace" key={answer.answer} onClick={() => nextQuestion(answer)}>
                                        {answer.answer}
                                    </button>;
            })}
                            </div>
                        </>) : <>
                        {postCards.length > 0 ? <>
                            {showEndcardText && postCards[0].type === '2' ? <p>
                                {selectedAnswer !== null ? selectedAnswer?.endcard : ''}
                            </p> : ''}
                            <ConfiguratorTab updateModalTitle={(title) => setPostcardTitle(title)} className="sitzkrieger-collection__tabs" postCards={postCards}/>
                        </> : ''}
                        {postCards[0] && postCards[0].hasGuide ? <>
                            <div className="sitzkrieger-collection__flex--center mt-8">
                                <button className="sitzkrieger-collection__button sitzkrieger-collection__button--blue" onClick={() => downloadExercise(categoryNumber)}>
                                    {postCards[0].type === '1' ? exercisesLabels.downloads : trainingLabels.downloads}
                                </button>
                            </div>
                        </> : ''}
                        {postCards[0] && postCards[0].relatedVideos.length > 0 ? <div className="mt-8">
                            <h4 className="text-lg text-brand-primary">{trainingLabels.relatedVideos}</h4>
                            <div className="flex">
                                {postCards[0].relatedVideos.map((relatedVideo, index) => {
                    return <a href={relatedVideo.url} title={relatedVideo.title} key={index + '-' + relatedVideo.title} className='sitzkrieger-collection__tabs__tabButton sitzkrieger-collection__tabs__tabButton--fixedHeight'>
                                        <div className={'absolute top-0 right-0 flex flex-row-reverse'}>
                                            <FavouriteButton inFavourites={relatedVideo.isInFavourites || false} videoId={Number(relatedVideo.id)}/>
                                        </div>
                                        <img alt={relatedVideo.title} src={relatedVideo.thumbnail}/>
                                        <PlayIcon style={{ position: 'absolute', color: 'white' }}/>
                                    </a>;
                })}
                            </div>
                        </div> : ''}
                    </>}
                </div>
                <footer className="sitzkrieger-collection__footer">
                    <button className="sitzkrieger-collection__button sitzkrieger-collection__button--backButton" onClick={resetConfigurator}>Zurück zum Anfang
                    </button>
                </footer>
            </section>
        </ReactModal>
    </div>;
};
ConfiguratorModal.displayName = 'ConfiguratorModal';
export default ConfiguratorModal;
