import axios from 'axios';
import CommentSentEvent from '../Events/CommentSentEvent';
const registerForm = (formName, callbackOnSubmit = null) => {
    const forms = document.getElementsByName(formName);
    if (forms) {
        forms.forEach((form) => {
            form.addEventListener('submit', (event) => {
                event.preventDefault();
                form.checkValidity();
                form.reportValidity();
                axios.post(form.action, Object.fromEntries(new FormData(form))).then(() => {
                    form.parentNode?.querySelector('.js-afterform-' + formName)?.classList?.toggle('hidden');
                    form.classList.toggle('hidden');
                    if (callbackOnSubmit !== null) {
                        callbackOnSubmit();
                    }
                });
                if (form.dataset.hasOwnProperty('downloadfile') && form.dataset.downloadfile) {
                    const a = document.getElementById('js-download-' + form.dataset.downloadfile);
                    a?.click();
                }
                return false;
            }, false);
        });
    }
};
const forms = () => {
    registerForm('writeComment', () => { document.dispatchEvent(CommentSentEvent); });
    const selector = document.querySelectorAll('.js-register-form');
    selector.forEach((node) => {
        const name = node.dataset?.name || '';
        if (name) {
            registerForm(name, () => { }); // eslint-disable-line @typescript-eslint/no-empty-function
        }
    });
};
export default forms;
