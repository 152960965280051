import * as React from 'react';
import { useEffect } from 'react';
import CourseModuleItem from './CourseModuleItem';
import Spinner from '../Spinner';
import { useCourseModules } from './Hooks/useCourseModules';
import { useCourseProgress } from './Hooks/useCourseProgess';
const CourseModuleList = (props) => {
    const [currentCourseLecture, setCurrentCourseLecture] = React.useState(null);
    const { isLoading, data: courseModules } = useCourseModules(props.courseUuid);
    const { isLoading: isCourseProgressLoading, data: courseProgress } = useCourseProgress(props.courseUuid);
    useEffect(() => {
        if (!isCourseProgressLoading && !isLoading) {
            courseModules?.forEach((item => {
                item.lectures.forEach((lecture) => {
                    console.log(courseProgress?.currentLectureUuid && lecture.uuid === courseProgress.currentLectureUuid);
                    if (courseProgress?.currentLectureUuid && lecture.uuid === courseProgress.currentLectureUuid) {
                        setCurrentCourseLecture({ lectureUuid: lecture.uuid, moduleUuid: item.uuid });
                    }
                });
            }));
        }
    }, [isCourseProgressLoading, isLoading]);
    if (isLoading) {
        return <Spinner />;
    }
    return <div className="h-full overflow-y-auto grid grid-cols-1 gap-6">
        {currentCourseLecture !== null ? <>
            <a href={`/course/${props.courseUuid}/modules/${currentCourseLecture.moduleUuid}/lectures/${currentCourseLecture.lectureUuid}?redirectUrl=${props.courseOverviewLink}`} className="inline-flex items-center gap-x-2 rounded-md bg-gray-100 px-3.5 py-2.5 text-base font-semibold text-gray-900 shadow-sm md:bottom-24 md:right-12">Direkt
                aktive Lektion starten</a>
        </> : <></>}
        {courseModules && courseModules.map((courseModule, index) => (<CourseModuleItem key={courseModule.uuid} courseOverviewLink={props.courseOverviewLink} courseUuid={props.courseUuid} courseModule={courseModule} defaultOpen={index <= 0}/>))}
    </div>;
};
CourseModuleList.displayName = 'CourseModuleList';
export default CourseModuleList;
