import * as React from 'react';
const MatteIcon = () => {
    return <>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 992.54 992.54" fill="currentColor">
            <g id="Layer_1" data-name="Layer 1">
                <path strokeMiterlimit="10" strokeWidth="7px" d="m547.02,473.2l6.92-7.07c20.15-20.6,30.91-48.92,29.53-77.7-1.38-28.78-14.81-55.94-36.85-74.51-19.83-16.71-45.02-25.38-70.93-24.4-25.91.98-50.38,11.52-68.89,29.68,0,0-162.17,157.38-166.92,162.54-19.46,21.15-29.04,48.49-28.49,81.25.21,12.49,3.67,55.84,39.36,89.07,30.56,28.46,66.94,32.98,86.96,32.98.57,0,1.13,0,1.67-.01,32.8-.42,277.06-.27,279.53-.27h3.16s208.82-207.32,208.82-207.32l-283.86-4.26Zm-129.48-143.06c32.81-32.19,84.06-34.13,119.21-4.5,19.06,16.07,30.22,38.63,31.41,63.52,1.2,24.9-7.74,48.43-25.18,66.25l-109.74,112.18c2.56-17.31-.32-35.76-8.62-55.12-7.99-18.63-20.96-34.19-38.55-46.23-12.8-8.76-24.42-13.03-28.82-14.46-19.92-6.47-41.19-7.01-61.11-2.35l121.39-119.29Zm198.21,339.31c-28.91-.02-245.72-.12-276.57.27-17.32.21-50.47-3.24-77.99-28.86-31.27-29.12-34.3-67.15-34.48-78.11-.41-24.75,5.65-45.82,18.04-62.8l20.74-20.38c24.57-17.38,57.49-22.79,87.03-13.2,6.91,2.24,42.21,15.3,58.02,52.16,9.97,23.24,10.91,44.64,2.78,63.63-2.31,5.41-5.22,10.26-8.55,14.6-.07.09-.14.18-.21.28-15.66,20.23-40.73,29.23-59.99,30.65-26.19,1.93-47.62-5.61-61.97-21.82-1.06-1.2-25.86-29.76-13.85-63.4,6.82-19.08,25.44-38.92,51.68-40.2,18.21-.9,40.68,6.88,49.82,26.26,6.68,14.17,6.73,35.1-6.1,47.69-1.26,1.24-12.75,12.02-27.6,9.74-14.68-2.26-26.65-15.92-29.11-33.21-.59-4.19-4.48-7.11-8.66-6.51-4.19.59-7.1,4.47-6.51,8.66,3.4,23.92,20.65,42.92,41.95,46.2,21.77,3.35,37.7-11.03,40.67-13.95,18.76-18.42,17.94-46.69,9.23-65.16-12.16-25.79-41.12-36.18-64.43-35.04-28.84,1.42-55.1,21.65-65.36,50.36-6.17,17.27-5.47,36.03,2.02,54.24,5.67,13.79,13.31,22.8,14.79,24.47,12.3,13.9,35.23,29.85,74.57,26.94,22.65-1.67,52.14-12.36,70.87-36.41l115.8-118.38c.46.09.94.15,1.42.16l260.44,3.91-178.5,177.21Z"/>
            </g>
        </svg>
    </>;
};
MatteIcon.displayName = 'MatteIcon';
export default MatteIcon;
