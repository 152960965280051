import * as React from 'react';
// @ts-ignore
import parse from 'html-react-parser';
import CustomSlider from './Mediathek/CustomSlider';
const TestimonialSlider = (props) => {
    return <CustomSlider slidesXl={1} slidesLg={1} slidesMd={1} slidesSm={1} count={props.data.length} infinity={!props.disableInfinity}>
        {props.data.map((data, index) => <React.Fragment key={index + '-' + data.name}>
            <div className="max-w-screen-xl items-center flex flex-col w-full p-4 m-auto">
                <div className="prose w-4/5 mx-2 text-center text-brand-darkgray text-2xl font-light italic leading-normal">
                    {parse(data.wysiwyg)}
                </div>
                <div className="flex flex-wrap justify-center my-4">
                    {parse(data.image)}
                    <div className="flex flex-col content-center justify-center mx-6 my-4">
                        <div className="text-brand-primary font-bold">{data.name}</div>
                        <div className="text-sm text-brand-darkgray">{data.occupation}</div>
                    </div>
                </div>
            </div>
        </React.Fragment>)}
    </CustomSlider>;
};
TestimonialSlider.displayName = 'TestimonialSlider';
export default TestimonialSlider;
