import * as React from 'react';
import { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import ResultButton from './ResultButton';
import MediaSkeleton from './MediaSkeleton';
import GlobalSearchFilterBar from './GlobalSearchFilterBar';
import { GlobalsSearchContext } from './GlobalSearch';
import MediaElement from '../Mediathek/MediaElement';
const GlobalSearchMegaFlyout = () => {
    const { isOpen, isFetching, resultCount, mediaElements, handleCloseMegaFlyout, handleInputChange } = React.useContext(GlobalsSearchContext);
    const localMediaElements = [];
    for (const mediaElement of mediaElements) {
        localMediaElements.push(<MediaElement key={mediaElement.id} video={mediaElement}/>);
    }
    return <Popover className="z-50 shadow">
        <Transition show={isOpen} as={Fragment} enter="transition ease-out duration-200" enterFrom="opacity-0 -translate-y-1" enterTo="opacity-100 translate-y-0" leave="transition ease-in duration-150" leaveFrom="opacity-100 translate-y-0" leaveTo="opacity-0 -translate-y-1">
            <Popover.Panel className="absolute inset-x-0 top-0 -z-10 bg-white pt-24 shadow-lg ring-1 ring-gray-900/5">
                <section className="block sm:hidden px-4 mb-4">
                    <input id="search" onChange={handleInputChange} className="block w-full rounded-md border border-gray-400 bg-white py-1.5 px-3 text-gray-900 shadow-sm" placeholder="Suche" type="search" name="search"/>
                </section>
                <section aria-labelledby="filter-heading">
                    <GlobalSearchFilterBar />
                </section>
                <section className="mx-auto content-start grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 3xl:grid-cols-6 gap-x-4 px-6 py-6 justify-items-center max-h-128 sm:max-h-none overflow-auto">
                    {isFetching
            ? <MediaSkeleton />
            : <>
                            {resultCount > 0
                    ? localMediaElements
                    : <div className="h-48 min-h-full flex items-center">
                                    <span>Es wurden keine Einträge gefunden.</span>
                                </div>}
                        </>}
                </section>
                <section className="flex justify-between items-center bg-gray-50 px-8 py-4">
                    <div></div>
                    <button type="button" className="rounded-full bg-transparent p-1 text-brand-primary hover:text-brand-secondary" onClick={handleCloseMegaFlyout}>
                        <svg className="h-7 w-7" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2"><path strokeLinecap="round" strokeLinejoin="round" d="M5 15l7-7 7 7"/></svg>
                    </button>
                    <ResultButton />
                </section>
            </Popover.Panel>
        </Transition>
    </Popover>;
};
GlobalSearchMegaFlyout.displayName = 'GlobalSearchMegaFlyout';
export default GlobalSearchMegaFlyout;
