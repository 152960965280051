import * as React from 'react';
import * as Sentry from '@sentry/browser';
import { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ProfileContext } from '../ProfileVideoCategoryContainer';
import Spinner from '../../Spinner';
import ProfileVideoModalVideoGrid from './ProfileVideoModalVideoGrid';
import { request } from '../../../../Utilities/api';
import { useI18n } from '../../../../Translation';
const ProfileVideoModal = () => {
    const i18n = useI18n();
    const { isOpen, handleOpen, currentCategory, setMediaElements } = React.useContext(ProfileContext);
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [notFound, setNotFound] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                setNotFound(false);
                const response = await request(currentCategory.endpoint, {
                    method: 'GET'
                });
                if (response.status === 200) {
                    const data = await response.json();
                    if (data.videos.length <= 0) {
                        setNotFound(true);
                    }
                    else {
                        setMediaElements(data.videos);
                    }
                }
                else {
                    setHasError(true);
                }
            }
            catch (error) {
                Sentry.captureException(error);
                setHasError(true);
            }
            finally {
                setIsLoading(false);
            }
        };
        if (isOpen) {
            fetchData();
        }
    }, [isOpen]);
    return <Transition.Root show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={handleOpen}>
            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
            </Transition.Child>
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:mb-8 sm:w-full sm:max-w-7xl">
                            <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                                <div className="bg-brand-primary px-4 py-6 sm:px-6">
                                    <div className="flex items-center justify-between">
                                        <Dialog.Title className="text-lg font-semibold leading-6 text-white">
                                            {currentCategory.title}
                                        </Dialog.Title>
                                        <div className="ml-3 flex h-7 items-center">
                                            <button type="button" className="text-white hover:text-gray-200 text-xl" onClick={handleOpen}>
                                                <span className="sr-only">{i18n['profile.modal.close']}</span>
                                                <i className="fas fa-times"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="px-4 py-6 sm:px-6">
                                {isLoading &&
            <div className="w-full flex justify-center items-center">
                                        <Spinner />
                                    </div>}
                                {notFound &&
            <div className="w-full flex flex-col gap-y-5 justify-center items-center">
                                        <div className="flex h-24 w-24 items-center justify-center rounded-full bg-yellow-200">
                                            <i className="text-4xl fas fa-video-slash"></i>
                                        </div>
                                        <p className="w-full text-xl text-center font-bold leading-7 text-gray-900 sm:text-3xl sm:tracking-tight">
                                            {currentCategory.notFoundText}
                                        </p>
                                    </div>}
                                {hasError &&
            <div className="w-full flex flex-col gap-y-5 justify-center items-center">
                                        <div className="flex h-24 w-24 items-center justify-center rounded-full bg-red-200">
                                            <i className="text-4xl fas fa-exclamation-triangle"></i>
                                        </div>
                                        <span className="text-xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                                            {i18n['profile.modal.errorOccurred']}
                                        </span>
                                    </div>}
                                {!notFound && !isLoading &&
            <ProfileVideoModalVideoGrid />}
                            </div>
                            <div className="px-4 py-6 sm:px-6 sm:flex sm:justify-end">
                                <button type="button" className="inline-flex w-50 justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0" onClick={handleOpen}>
                                    {i18n['profile.modal.close']}
                                </button>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </div>
        </Dialog>
    </Transition.Root>;
};
ProfileVideoModal.displayName = 'ProfileVideoModal';
export default ProfileVideoModal;
