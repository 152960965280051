import * as React from 'react';
import { useState } from 'react';
import BaseElement from '../../CustomElements/BaseElement';
import { UserContext } from '../../Context/UserContext';
import { activateModule } from '../../../Utilities/api';
import { toast } from 'react-hot-toast';
const ModuleActivationButton = ({ moduleId, title, titleWhenActivated, isActivated }) => {
    const user = React.useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [active, setActive] = useState(isActivated || false);
    const buttonText = title ? title : 'Module aktivieren';
    const buttonTextActivated = titleWhenActivated ? titleWhenActivated : 'Modul bereits aktiviert';
    if (user === null) {
        return <button is="no-permission-button" className="flex text-lg font-bold shadow-md p-4 py-2 my-2 border border-transparent bg-brand-secondary rounded-full text-white hover:opacity-80">{buttonText}</button>;
    }
    const onClick = async () => {
        if (loading) {
            return;
        }
        setLoading(true);
        const result = await activateModule(moduleId);
        if (result.success !== true) {
            toast.error('Modul konnte nicht aktiviert werden');
        }
        else {
            if (result.new === true) {
                toast.success('Erfolgreich aktiviert.');
                setActive(true);
            }
            else {
                toast.success('Bereits aktiviert.');
            }
        }
        setLoading(false);
    };
    return <button onClick={onClick} disabled={loading} className="flex text-lg font-bold shadow-md p-4 py-2 my-2 border border-transparent bg-brand-secondary rounded-full text-white hover:opacity-80">
        {active ? buttonTextActivated : buttonText}
    </button>;
};
export default ModuleActivationButton;
class ModuleActivationButtonElement extends BaseElement {
    constructor() {
        super(...arguments);
        this.element = ModuleActivationButton;
    }
}
export const loadModuleActivationButton = () => {
    customElements.define('module-activate-button', ModuleActivationButtonElement);
};
