import * as React from 'react';
const FaszienrolleIcon = () => {
    return <>
        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="512" zoomAndPan="magnify" viewBox="0 0 384 383.999986" height="512" preserveAspectRatio="xMidYMid meet" version="1.0">
            <path d="M 192.007812 -0.015625 C 85.960938 -0.015625 0 85.984375 0 192.027344 C 0 298.039062 85.960938 384 192.007812 384 C 298.054688 384 384.015625 298.039062 384.015625 192.027344 C 384.015625 85.984375 298.054688 -0.015625 192.007812 -0.015625 Z M 192.007812 285.671875 C 140.445312 285.671875 98.617188 243.878906 98.617188 192.316406 C 98.617188 140.753906 140.445312 98.964844 192.007812 98.964844 C 243.570312 98.964844 285.359375 140.753906 285.359375 192.316406 C 285.359375 243.878906 243.570312 285.671875 192.007812 285.671875 Z M 192.007812 285.671875 "/>
        </svg>
    </>;
};
FaszienrolleIcon.displayName = 'FaszienrolleIcon';
export default FaszienrolleIcon;
