import * as React from 'react';
import { useEffect, useState } from 'react';
import { getVideoSeries } from '../../Utilities/api';
import { toast } from 'react-hot-toast';
import VideoCategoryList from './Mediathek/VideoCategoryList';
import VideoPlayer from './VideoPlayer';
const VideoSeries = (props) => {
    const [videoSeries, setVideoSeries] = useState(null);
    const [currentVideo, setCurrentVideo] = useState(null);
    useEffect(() => {
        loadVideoSeries();
    }, []);
    const loadVideoSeries = () => {
        getVideoSeries(props.videoSeriesId).then((res) => {
            if (res.success === false) {
                toast.error(res.message);
            }
            else {
                setVideoSeries(res);
                setCurrentVideo(res.currentVideo);
            }
        });
    };
    return <>
        {videoSeries !== null && currentVideo !== null ? <section>
            <div className={'max-w-[900px] m-auto'}>
                <div className={props.backgroundClass + ' m-auto'}>
                    <VideoPlayer {...currentVideo} id={Number(currentVideo.id)} controls={true} playing={true} muted={false} responsive={false} preview={true} withMargin={true} redirectToOnFinished={() => {
                loadVideoSeries();
            }} onProgress={(progress) => {
                if (progress.playedSeconds > 2 && progress.playedSeconds % 3 < 1) {
                    // @ts-ignore
                    fetch(`${videoSeries.currentVideo.onWatchVideo}?elapsed=${progress.playedSeconds}`, { method: 'GET' });
                }
            }}/>
                    {currentVideo.paper !== null ?
                <div className="w-full">
                            <a href={currentVideo.paper} className="w-full md:w-1/2 max-w-max flex text-lg font-bold shadow-md items-center justify-center p-4 py-2 my-2 border border-transparent bg-brand-primary rounded-full text-white hover:opacity-80 m-auto">Download Paper</a>
                        </div> :
                <></>}
                </div>
            </div>
            <VideoCategoryList videos={videoSeries.videos} categories={[]} categoriesSorting={[]} productAsSlider={'Show slider'} lockWithPreview={true} disableProductSliderTitle={true} mediaElementClickCallback={(videoId) => {
                videoSeries.videos.forEach((el) => {
                    if (Number(el.id) === Number(videoId)) {
                        setCurrentVideo(el);
                    }
                });
            }} forVideoSeries={true}/>
        </section> : ''}
    </>;
};
VideoSeries.displayName = 'VideoSeries';
export default VideoSeries;
