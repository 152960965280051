import BaseElement from './BaseElement';
import MediaElement from '../Components/Mediathek/MediaElement';
class SingleMediaElement extends BaseElement {
    constructor() {
        super(...arguments);
        this.element = MediaElement;
    }
}
export const loadMediaElementWebComponent = () => {
    customElements.define('media-element', SingleMediaElement);
};
