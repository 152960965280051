import BaseElement from './BaseElement';
import Challenge from '../Components/Challenge/Challenge';
class ChallengePlayer extends BaseElement {
    constructor() {
        super(...arguments);
        this.element = Challenge;
    }
}
export const loadChallengePlayerWebComponent = () => {
    customElements.define('challenge-player', ChallengePlayer);
};
