import * as React from 'react';
import { useState } from 'react';
import BaseElement from '../../CustomElements/BaseElement';
import { UserContext } from '../../Context/UserContext';
import { subscribeToNotification } from '../../../Utilities/api';
const NotificationSubscriptionButton = ({ active, notificationKey, title }) => {
    const user = React.useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [subscribed, setSubscribed] = useState(active);
    const buttonText = title ? title : 'Benachrichtigung';
    if (user === null) {
        return <></>;
    }
    const onClick = async () => {
        if (loading) {
            return;
        }
        const checked = !subscribed;
        setSubscribed(checked);
        setLoading(true);
        const result = await subscribeToNotification(notificationKey, !checked);
        if (result.success !== true) {
            setSubscribed(!checked);
        }
        setLoading(false);
    };
    return <button onClick={onClick} disabled={loading} className="inline-flex items-center gap-x-2 rounded-md bg-brand-primary px-3.5 py-2.5 text-base font-semibold text-white shadow-sm hover:bg-brand-secondary md:bottom-24 md:right-12">
        {subscribed ? <>
            <i className="fa-solid fa-bell"></i>
            {buttonText} aktiv
        </> : <>
            <i className="fa-regular fa-bell"></i>
            {buttonText} aktivieren
        </>}
    </button>;
};
class NotificationSubscriptionButtonElement extends BaseElement {
    constructor() {
        super(...arguments);
        this.element = NotificationSubscriptionButton;
    }
}
export const loadNotificationSubscriptionButton = () => {
    customElements.define('notification-subscription-button', NotificationSubscriptionButtonElement);
};
