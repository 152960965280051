import * as React from 'react';
import { useState, useEffect } from 'react';
import { ProfileContext } from '../ProfileVideoCategoryContainer';
import VideoPlayer from '../../VideoPlayer';
import FavoriteButton from '../../Mediathek/FavoriteButton';
import IconSet from '../../Mediathek/IconSet';
import { generateUrl } from '../../../../Utilities/api';
import ProfileVideoModalSidebarVideo from './ProfileVideoModalSidebarVideo';
import { useI18n } from '../../../../Translation';
const ProfileVideoModalVideoGrid = () => {
    const i18n = useI18n();
    const { mediaElements } = React.useContext(ProfileContext);
    const [currentMediaElement, setCurrentMediaElement] = useState();
    const [sidebarMediaElements, setSidebarMediaElements] = useState([]);
    useEffect(() => {
        if (currentMediaElement == undefined) {
            setCurrentMediaElement(mediaElements[0]);
        }
        const localSidebarMediaElements = [];
        for (const video of mediaElements) {
            localSidebarMediaElements.push(<ProfileVideoModalSidebarVideo key={video.id} video={video} isCurrent={currentMediaElement?.id == video.id} handleClick={() => setCurrentMediaElement(video)}/>);
        }
        setSidebarMediaElements(localSidebarMediaElements);
    }, [mediaElements, currentMediaElement]);
    return <div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-3 lg:gap-4">
        <div className="grid grid-cols-1 lg:col-span-2">
            {currentMediaElement &&
            <>
                    <VideoPlayer {...currentMediaElement} id={parseInt(currentMediaElement?.id)} controls={true} playing={true} muted={false} responsive={false} preview={true}/>

                    <div className="pt-4">
                        <div className="rounded-lg bg-gray-100 shadow p-6">
                            <div className="mb-4 flex items-center">
                                <FavoriteButton infavorites={currentMediaElement.inFavourites.toString()} videoid={parseInt(currentMediaElement?.id)}></FavoriteButton>
                            </div>
                            <div className="mb-4">
                                {currentMediaElement.title}
                            </div>
                            <div className="mb-8">
                                <dl className="grid grid-cols-2 sm:grid-cols-4 gap-y-4">
                                    <div>
                                        <dt className="mb-2 font-cabin text-xs font-medium">{i18n['profile.modal.videoDetail.videoLength']}</dt>
                                        <dd className="text-sm font-semibold">-</dd>
                                    </div>
                                    <div>
                                        <dt className="mb-2 font-cabin text-xs font-medium">{i18n['profile.modal.videoDetail.videoPublished']}</dt>
                                        <dd className="text-sm font-semibold">-</dd>
                                    </div>

                                    {currentMediaElement.chapterIcons.length > 0 &&
                    <div>
                                        <dt className="mb-2 font-cabin text-xs font-medium">{i18n['profile.modal.videoDetail.videoContents']}</dt>
                                        <dd className="text-sm font-semibold">
                                            <div className="flex items-center">
                                                <IconSet icons={currentMediaElement.chapterIcons}/>
                                            </div>
                                        </dd>
                                    </div>}

                                    {currentMediaElement.gymEquipments.length > 0 &&
                    <div>
                                            <dt className="mb-2 font-cabin text-xs font-medium">{i18n['profile.modal.videoDetail.videoEquipment']}</dt>
                                            <dd className="text-sm font-semibold">
                                                <IconSet icons={currentMediaElement.gymEquipments}/>
                                            </dd>
                                        </div>}
                                </dl>
                            </div>
                            {currentMediaElement.tags &&
                    <div className="mb-2 mt-8 flex flex-wrap items-center gap-2">
                                    {currentMediaElement.tags.map((tag) => (<a key={tag.title} href={generateUrl('global-search-result-page', { tags: tag.title })} className="inline-block items-center rounded-md bg-cyan-50 px-2 py-1 text-xs font-medium text-cyan-700 ring-1 ring-inset ring-cyan-700/10">
                                            {tag.title}
                                        </a>))}
                                </div>}
                        </div>
                    </div>
                </>}
        </div>
        <div className="grid grid-cols-1 gap-4">
            <div className="overflow-y-auto max-h-192 rounded-lg bg-gray-100 shadow">
                <div>
                    {sidebarMediaElements}
                </div>
            </div>
        </div>
    </div>;
};
ProfileVideoModalVideoGrid.displayName = 'ProfileVideoModalVideoGrid';
export default ProfileVideoModalVideoGrid;
