import * as React from 'react';
import { useEffect } from 'react';
import { useI18n } from '../../../Translation';
import Spinner from '../Spinner';
import { useUpdateCourseProgress } from './Hooks/useUpdateCourseProgress';
import { toast } from 'react-hot-toast';
const CourseLectureProgressButton = (props) => {
    const mutation = useUpdateCourseProgress();
    const i18n = useI18n();
    useEffect(() => {
        if (mutation.data && !mutation.data.success) {
            toast.error(i18n['course.watchtime.threshold']);
        }
    }, [mutation.data]);
    return <>
        <button type="button" className="inline-flex items-center gap-x-2 rounded-md bg-brand-secondary px-3.5 py-2.5 text-base font-semibold text-white shadow-sm md:bottom-24 md:right-12" onClick={() => {
            mutation.mutate({ courseUuid: props.courseUuid, lectureUuid: props.lectureUuid });
        }}>
            {mutation.isPending ? (<Spinner />) : (i18n['course.finishLecture'])}
        </button>
    </>;
};
export default CourseLectureProgressButton;
