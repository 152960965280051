import * as React from 'react';
import { useState } from 'react';
import VideoPlayer from '../VideoPlayer';
import parse from 'html-react-parser';
import { useInterval } from '../../../Utilities/customHooks';
import { getLiveVideos } from '../../../Utilities/api';
const Liveplayer = (props) => {
    if (props.video.data.type === 'broadcast') {
        if (props.video.data?.isRecording === true) {
            return <FallbackPlayer video={props.video}/>;
        }
        return <BroadcastPlayer video={props.video} liveplayerTypeId={props.livePlayerTypeId || 0}/>;
    }
    if (props.video.data.type === 'video' || props.video.data.type === 'youtube') {
        return <FallbackPlayer video={props.video}/>;
    }
    return <></>;
};
const FallbackPlayer = ({ video }) => {
    const [isLive, setIsLive] = useState(video.data.isLive || false);
    useInterval(() => {
        if (Number((new Date).getTime() / 1000) >= (video.data.scheduled || 0)) {
            setIsLive(true);
        }
    }, 1000);
    if (!isLive) {
        return <div className="sitzkrieger-collection__liveplayer">{parse(video.data.light || '')}</div>;
    }
    return <div className="sitzkrieger-collection__liveplayer">
        <VideoPlayer {...video} id={video.data.id}/>
    </div>;
};
const BroadcastPlayer = ({ video, liveplayerTypeId, }) => {
    const [liveVideo, setLiveVideo] = useState(video);
    useInterval(() => {
        getLiveVideos(liveplayerTypeId).then(res => {
            if (Array.isArray(res) && res.length > 0) {
                setLiveVideo({
                    ...liveVideo,
                    muted: false,
                    playing: true,
                    data: res[0],
                });
            }
        });
    }, 5000);
    return <div className="sitzkrieger-collection__liveplayer">
        {video.enabled && liveVideo.data.isLive ? <VideoPlayer {...liveVideo} id={liveVideo.data.id} redirectToOnFinished={() => console.info('tbd')}/> : <></>}
        {typeof video.data.light === 'string' && !liveVideo.data.isLive ? parse(video.data.light) : <></>}
    </div>;
};
Liveplayer.displayName = 'Liveplayer';
BroadcastPlayer.displayName = 'BroadcastPlayer';
export default Liveplayer;
