import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import Countdown from 'react-countdown';
const CountdownElement = ({ label, value }) => {
    return <div className={'flex flex-col'}>
        <div className="sitzkrieger-collection__liveplayer__countdown__body__tile text-white bg-brand-lightblue1">
            <p>{value}</p>
        </div>
        <div className={'text-center mt-2'}>{label}</div>
    </div>;
};
CountdownElement.displayName = 'CountdownElement';
const CountdownBody = ({ days, hours, minutes, seconds, completed, }) => {
    if (completed) {
        return <></>;
    }
    return <div className="sitzkrieger-collection__liveplayer__countdown__wrapper">
        <div className="sitzkrieger-collection__liveplayer__countdown__body">
            <CountdownElement label="Tage" value={days}/>
            <CountdownElement label="Stunden" value={hours}/>
            <CountdownElement label="Minuten" value={minutes}/>
            <CountdownElement label="Sekunden" value={seconds}/>
        </div>
    </div>;
};
CountdownBody.displayName = 'CountdownBody';
const CountdownComponent = (props) => {
    const [content, setContent] = useState({
        title: props.title,
        subtitle: props.subtitle,
        text: props.text,
    });
    const [completed, setCompleted] = useState(false);
    const countdown = useRef(null);
    const updateText = () => {
        setContent({
            title: props.finishedTitle,
            subtitle: props.finishedSubtitle,
            text: props.finishedText,
        });
        setCompleted(true);
    };
    useEffect(() => {
        if (countdown.current && countdown.current.mounted && countdown.current.isCompleted()) {
            updateText();
        }
    }, []);
    let date = props.date;
    if (props.overrideWithTimestamp) {
        date = new Date(props.overrideWithTimestamp * 1000).toISOString();
    }
    return <div className={`sitzkrieger-collection__liveplayer__countdown ${props.labelClass}`}>
        <h3 className="sitzkrieger-collection__liveplayer__countdown__headline">{content.title}</h3>
        {content.subtitle ?
            <h4 className="sitzkrieger-collection__liveplayer__countdown__headline--subtitle">{content.subtitle}</h4> : ''}
        {<Countdown ref={countdown} renderer={CountdownBody} onComplete={updateText} date={date}/>}
        {completed && !props.nowLiveDisabled ?
            <div className="sitzkrieger-collection__liveplayer__badge">Jetzt Live</div> : ''}
        <p className="sitzkrieger-collection__liveplayer__countdown__subtext" dangerouslySetInnerHTML={{ __html: content.text }}/>
    </div>;
};
CountdownComponent.displayName = 'CountdownComponent';
export default CountdownComponent;
