import * as React from 'react';
import * as Sentry from '@sentry/browser';
import { useState, useEffect } from 'react';
import { myAreaStatsActiveDays } from '../../../../Utilities/api';
import Spinner from '../../Spinner';
import ProfileStatsInfoToolTip from './ProfileStatsInfoToolTip';
const ProfileStatsTileWatchedVideos = ({ label }) => {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await myAreaStatsActiveDays();
                setData(result);
            }
            catch (error) {
                Sentry.captureException(error);
                setHasError(true);
            }
            finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, []);
    return <div className="relative overflow-hidden rounded-lg bg-white px-4 py-5 shadow-lg sm:p-6">
        <ProfileStatsInfoToolTip content={'Aktive Tage innerhalb der letzten 7 Tage'}/>

        <div className="overflow-hidden">
            <dt className="truncate text-sm font-medium text-gray-500">{label}</dt>
            {isLoading ? (<Spinner />) : (<>
                    {hasError ? (<i className="mt-1">Fehler beim Laden</i>) : data ? (<dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{data.logins_last_7_days}</dd>) : (<></>)}
                </>)}
        </div>
    </div>;
};
ProfileStatsTileWatchedVideos.displayName = 'ProfileStatsTileWatchedVideos';
export default ProfileStatsTileWatchedVideos;
