import * as React from 'react';
const ClockIconCube = () => {
    return <>
        <div className="flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-lg bg-purple-500">
            <svg className="h-7 w-7 text-white" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"/>
            </svg>
        </div>
    </>;
};
ClockIconCube.displayName = 'ClockIconCube';
export default ClockIconCube;
