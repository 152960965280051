import * as React from 'react';
import GlobalSearchFilter from './GlobalSearchFilter';
import { GlobalsSearchContext } from './GlobalSearch';
const GlobalSearchFilterBar = () => {
    const { searchString, handleFilterReset, filterItems } = React.useContext(GlobalsSearchContext);
    const typeOptions = filterItems.videoTypes.map((item) => {
        return { value: item.key, label: item.label };
    });
    const specialForOptions = [
        { value: 'office', label: 'Büro' },
        { value: 'home', label: 'Zuhause' },
    ];
    const durationOptions = [
        { value: '1', label: '1-5 Min' },
        { value: '5', label: '5-10 Min' },
        { value: '10', label: '10-15 Min' },
        { value: '15', label: '15-20 Min' },
    ];
    return <div className="border-b border-gray-200 bg-gray-100 py-4">
        <div className="mx-auto flex items-center justify-between px-4 sm:px-6 lg:px-8">
            <div className="flex items-center">
                <div className="-mx-4 flex items-center divide-x divide-gray-200">
                    <GlobalSearchFilter id={'types'} name={'Typ'} options={typeOptions}/>
                    <GlobalSearchFilter id={'specialFor'} name={'Speziell für'} options={specialForOptions}/>
                    <GlobalSearchFilter id={'duration'} name={'Dauer'} options={durationOptions}/>
                </div>
                <div className="pl-6 flex items-center">
                    <button onClick={handleFilterReset} type="button">
                        <span className="hidden sm:block text-gray-400">Filter zurücksetzen</span>
                        <span className="block sm:hidden text-gray-400">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="currentColor" viewBox="0 0 576 512"><path d="M3.9 22.9C10.5 8.9 24.5 0 40 0H472c15.5 0 29.5 8.9 36.1 22.9s4.6 30.5-5.2 42.5L396.4 195.6C316.2 212.1 256 283 256 368c0 27.4 6.3 53.4 17.5 76.5c-1.6-.8-3.2-1.8-4.7-2.9l-64-48c-8.1-6-12.8-15.5-12.8-25.6V288.9L9 65.3C-.7 53.4-2.8 36.8 3.9 22.9zM432 224a144 144 0 1 1 0 288 144 144 0 1 1 0-288zm59.3 107.3c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L432 345.4l-36.7-36.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L409.4 368l-36.7 36.7c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L432 390.6l36.7 36.7c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6L454.6 368l36.7-36.7z"/></svg>
                        </span>
                    </button>
                </div>
            </div>

            <div className="hidden sm:block relative inline-block text-left text-sm font-medium text-gray-700">
                {searchString.length > 0 &&
            <>Suche nach: <span className="text-sm font-semibold"> {searchString ? searchString : ''}</span></>}
            </div>
        </div>
    </div>;
};
GlobalSearchFilterBar.displayName = 'GlobalSearchFilterBar';
export default GlobalSearchFilterBar;
