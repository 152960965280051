import * as React from 'react';
import Countdown from 'react-countdown';
const CountdownElement = ({ label, value }) => {
    return <div className={'my-1 bg-white rounded-lg text-black px-1 mx-1 font-medium min-w-[65px]'}>
        <span className={'pr-1'}>{value}</span>
        <span className={''}>{label}</span>
    </div>;
};
const CountdownBody = ({ days, hours, minutes, seconds }) => {
    return <div className={'flex flex-row flex-wrap md:flex-nowrap mx-2'}>
        {days > 0 ? <CountdownElement label="Tage" value={days}/> : <></>}
        <CountdownElement label="Std" value={hours}/>
        <CountdownElement label="Min" value={minutes}/>
        <CountdownElement label="Sek" value={seconds}/>
    </div>;
};
const InlineCountdown = ({ date }) => {
    const countdownDate = new Date(date).toISOString();
    return <Countdown date={countdownDate} renderer={CountdownBody}/>;
};
InlineCountdown.displayName = 'InlineCountdown';
export default InlineCountdown;
