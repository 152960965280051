import BaseElement from './BaseElement';
import ProfileScoreContainer from '../Components/Profile/ProfileScoresContainer';
class ProfileScoreContainerElement extends BaseElement {
    constructor() {
        super(...arguments);
        this.element = ProfileScoreContainer;
    }
}
export const loadProfileScoreContainerElementWebComponent = () => {
    customElements.define('profile-score-container', ProfileScoreContainerElement);
};
