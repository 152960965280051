import * as React from 'react';
import { useEffect, useState } from 'react';
import * as Sentry from '@sentry/browser';
import ProfileVideoCategory from './Partials/ProfileVideoCategory';
import HeartPlusIconCube from './IconCubes/HeartPlusIconCube';
import ClockIconCube from './IconCubes/ClockIconCube';
import CoachIconCube from './IconCubes/CoachIconCube';
import StarIconCube from './IconCubes/StarIconCube';
import CalendarIconCube from './IconCubes/CalendarIconCube';
import ProfileVideoModal from './Partials/ProfileVideoModal';
import { generateUrl, myAreaVideoCategoryAccess } from '../../../Utilities/api';
import { useI18n } from '../../../Translation';
const i18n = useI18n();
const categories = [
    { identifier: 'healthscore', title: i18n['profile.videoCategories.healthScore.heading'], iconCube: <HeartPlusIconCube />, description: i18n['profile.videoCategories.healthScore.description'], endpoint: generateUrl('sitzkrieger_v2_myareaapi_recommendations', { recommendationType: 'healthscore' }), 'notFoundText': i18n['profile.videoCategories.healthScore.notFound'] },
    { identifier: 'history', title: i18n['profile.videoCategories.history.heading'], iconCube: <ClockIconCube />, description: i18n['profile.videoCategories.history.description'], endpoint: generateUrl('sitzkrieger_v2_myareaapi_videohistory'), 'notFoundText': i18n['profile.videoCategories.history.notFound'] },
    { identifier: 'trainer', title: i18n['profile.videoCategories.trainer.heading'], iconCube: <CoachIconCube />, description: i18n['profile.videoCategories.trainer.description'], endpoint: generateUrl('sitzkrieger_v2_myareaapi_recommendations', { recommendationType: 'trainer' }), 'notFoundText': i18n['profile.videoCategories.trainer.notFound'] },
    { identifier: 'favourites', title: i18n['profile.videoCategories.favourites.heading'], iconCube: <StarIconCube />, description: i18n['profile.videoCategories.favourites.description'], endpoint: generateUrl('sitzkrieger_v2_myareaapi_favouritevideos'), 'notFoundText': i18n['profile.videoCategories.favourites.notFound'] },
    { identifier: 'weekly', title: i18n['profile.videoCategories.weekly.heading'], iconCube: <CalendarIconCube />, description: i18n['profile.videoCategories.weekly.description'], endpoint: generateUrl('sitzkrieger_v2_myareaapi_recommendations', { recommendationType: 'weekly' }), 'notFoundText': i18n['profile.videoCategories.weekly.notFound'] },
];
export const ProfileContext = React.createContext(null);
const ProfileVideoCategoryContainer = () => {
    const [mediaElements, setMediaElements] = useState([]);
    const [isFetching, setFetching] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [currentCategory, setCurrentCategory] = useState({ identifier: '', title: '', iconCube: <></>, description: '', endpoint: '', notFoundText: '' });
    const [allowedCategories, setAllowedCategories] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await myAreaVideoCategoryAccess();
                setAllowedCategories(Object.entries(result).filter(([, allowed]) => allowed).map(([category]) => category));
            }
            catch (error) {
                Sentry.captureException(error);
            }
        };
        fetchData().then(() => {
            setFetching(false);
        });
    }, []);
    const handleOpen = () => {
        setIsOpen(!isOpen);
    };
    return <>
        <ProfileContext.Provider value={{
            isOpen,
            isFetching,
            mediaElements,
            currentCategory,
            handleOpen,
            setCurrentCategory,
            setMediaElements
        }}>
            <div className="mt-5 relative isolate overflow-hidden rounded-lg bg-white shadow-lg">
                <img src="/static/women-in-gym.png" alt="" className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center"/>
                <div className="p-8">
                    <div className="pb-5">
                        <h3 className="text-xl font-bold leading-7 sm:truncate sm:text-3xl sm:tracking-tight text-white">
                            {i18n['profile.profileVideoIntroHeading']}
                        </h3>
                        <p className="mt-2 max-w-4xl text-base text-gray-200">
                            {i18n['profile.profileVideoIntro']}
                        </p>
                    </div>
                    <ul role="list" className="grid grid-cols-1 gap-6 py-8 sm:grid-cols-2">
                        {categories
            .filter((category) => allowedCategories.includes(category.identifier))
            .map((category) => (<ProfileVideoCategory key={category.title} category={category}/>))}
                    </ul>
                </div>
            </div>
            <ProfileVideoModal />
        </ProfileContext.Provider>
    </>;
};
ProfileVideoCategoryContainer.displayName = 'ProfileVideoCategoryContainer';
export default ProfileVideoCategoryContainer;
