import * as React from 'react';
const HeartPlusIconCube = () => {
    return <>
        <div className="flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-lg bg-green-500">
            <svg className="h-8 w-8 text-white" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path fill="currentColor" fillRule="evenodd" d="M8.962 18.469C6.019 16.214 2 12.489 2 8.967C2 3.083 7.5.886 12 5.43C16.5.886 22 3.083 22 8.966c0 3.523-4.02 7.248-6.962 9.503C13.706 19.489 13.04 20 12 20c-1.04 0-1.706-.51-3.038-1.531ZM16.5 6.25a.75.75 0 0 1 .75.75v1.25h1.25a.75.75 0 0 1 0 1.5h-1.25V11a.75.75 0 0 1-1.5 0V9.75H14.5a.75.75 0 0 1 0-1.5h1.25V7a.75.75 0 0 1 .75-.75Z" clipRule="evenodd"/>
            </svg>
        </div>
    </>;
};
HeartPlusIconCube.displayName = 'HeartPlusIconCube';
export default HeartPlusIconCube;
