import * as React from 'react';
import BaseElement from '../../CustomElements/BaseElement';
import { useCourse } from './Hooks/useCourse';
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
// const stripePromise = loadStripe('pk_test_51PQRR1IsAsIFasKr1Nk7DlNai0louaIE1JVqw7Yu2mzoCa2vtnfy0aqYhWPVFqhQF5rFZWBL2ZfzcLqQu0CXOU6X00s1bmHZQI');
const stripePromise = loadStripe('pk_live_51KcBb9IVJgCk0rOrCXCxJu96stPtNgr7Qer0zHCmT9Ya15HT0YtN0t2zMBg98cDGtAThDVpQNmdv25ruqJPMQS1h00lPeQK4k5');
const CoursePayment = ({ courseUuid, clientSecret, returnUrl }) => {
    const { data: course } = useCourse(courseUuid);
    const options = {
        // passing the client secret obtained from the server
        clientSecret,
    };
    if (!course) {
        return <></>;
    }
    return <>
        <div className="pb-5">
            <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
                <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
        </div>
    </>;
};
CoursePayment.displayName = 'CoursePayment';
export default CoursePayment;
class CoursePaymentElement extends BaseElement {
    constructor() {
        super(...arguments);
        this.element = CoursePayment;
    }
}
export const loadCoursePaymentElement = () => {
    customElements.define('course-payment-element', CoursePaymentElement);
};
