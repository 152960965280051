import * as React from 'react';
import { buildQuery } from '../../../Utilities/buildQuery';
const NextLink = (props) => {
    if (props.currentPage < props.maxPage) {
        const queryString = buildQuery({
            ...props.queryFragments,
            page: (props.currentPage + 1)
        }, 'global-search-result-page');
        return <a href={queryString} key="nextLink" className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 320 512"><path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"/></svg>
        </a>;
    }
    return <></>;
};
const PreviousLink = (props) => {
    if (props.maxPage > 1 && props.currentPage > 1) {
        const queryString = buildQuery({
            ...props.queryFragments,
            page: (props.currentPage - 1),
        }, 'global-search-result-page');
        return <a href={queryString} key="previousLink" className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 320 512"><path d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"/></svg>
        </a>;
    }
    return <></>;
};
const PageLink = (props) => {
    const queryString = buildQuery({
        ...props.queryFragments,
        page: props.page,
    }, 'global-search-result-page');
    return <a href={queryString} aria-current="page" className={'relative inline-flex items-center px-4 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300  focus:z-20 focus:outline-offset-0 ' + (props.page === props.currentPage ? 'bg-brand-primary text-white ' : ' text-gray-900 hover:bg-gray-50 ')}>
        {props.page}
    </a>;
};
const PageLinkList = (props) => {
    if (props.maxPage > 1) {
        return <>
            {[...Array(props.maxPage).keys()].map((i) => <PageLink key={i} queryFragments={props.queryFragments} currentPage={props.currentPage} page={i + 1}/>)}
        </>;
    }
    return <></>;
};
const Pagination = (props) => {
    return <div className="flex justify-center sm:justify-end my-4">
        <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
            <PreviousLink {...props}/>
            <PageLinkList {...props}/>
            <NextLink {...props}/>
        </nav>
    </div>;
};
Pagination.displayName = 'Pagination';
export default Pagination;
