import React from 'react';
import BaseElement from '../CustomElements/BaseElement';
var AlertType;
(function (AlertType) {
    AlertType["red"] = "red";
    AlertType["yellow"] = "yellow";
    AlertType["green"] = "green";
})(AlertType || (AlertType = {}));
const getClassByType = (type) => {
    if (type === AlertType.green) {
        return 'text-green-800 bg-green-50';
    }
    if (type === AlertType.yellow) {
        return 'text-yellow-800 bg-yellow-50';
    }
    if (type === AlertType.red) {
        return 'text-red-800 bg-red-50';
    }
    throw new Error('AlertType not supported');
};
const AlertWithIcon = (props) => {
    return <div className={'flex p-4 mb-4 text-sm rounded-lg ' + getClassByType(props.type)} role="alert">
        <svg aria-hidden="true" className="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
        <span className="sr-only">{props.screenReaderLabel}</span>
        <div>
            <span className="font-medium">{props.title}</span> {props.text}
        </div>
    </div>;
};
class AlertWithIconElement extends BaseElement {
    constructor() {
        super(...arguments);
        this.element = AlertWithIcon;
    }
}
export const loadAlertWithIcon = () => {
    customElements.define('alert-with-icon', AlertWithIconElement);
};
