import * as React from 'react';
import * as Sentry from '@sentry/browser';
import { useEffect, useState } from 'react';
import HealthScoreDoughnutChart from '../../Charts/HealthScoreDoughnutChart';
import { HealthScoreColor } from '../../Charts/HealthScroreColorEnum';
import { generateUrl, myAreaStatsHealthScore } from '../../../../Utilities/api';
import Spinner from '../../Spinner';
import { useI18n } from '../../../../Translation';
const ProfileHealthScoreTile = () => {
    const i18n = useI18n();
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [notFound, setNotFound] = useState(false);
    const formatDateString = (dateString) => {
        const date = new Date(dateString);
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: 'numeric',
            minute: 'numeric',
            hourCycle: 'h23'
        };
        return date.toLocaleDateString('de-DE', options);
    };
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await myAreaStatsHealthScore();
                if (result == 404) {
                    setNotFound(true);
                }
                else {
                    setData(result);
                }
            }
            catch (error) {
                Sentry.captureException(error);
                setHasError(true);
            }
            finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, []);
    return <div className="overflow-hidden relative rounded-lg bg-white px-4 py-5 shadow-lg sm:p-6">
        {isLoading ? (<Spinner />) : (<>
                {hasError ? (<i className="mt-1">{i18n['profile.healthScore.errorWhileLoading']}</i>) : (<>
                        <div className="flex justify-between items-center">
                            <span className="text-xl font-semibold tracking-tight text-gray-900">Health Score</span>

                            {data ? (<span className="text-xs leading-6 text-gray-500">{i18n['profile.healthScore.resultDate'](formatDateString(data.creationDate))}</span>) : notFound ? (<></>) : (<></>)}
                        </div>

                        <div className="relative mt-5 mb-12">
                            {notFound ? (<div className="flex flex-col justify-center items-center">
                                    <HealthScoreDoughnutChart color={HealthScoreColor.green} score={'80'}/>

                                    <div className="absolute inset-0 backdrop-filter rounded-full backdrop-blur-lg flex flex-col gap-y-4 justify-center items-center">
                                        <p className="text-lg font-semibold max-w-[50%] text-center">
                                            {i18n['profile.healthScore.noTestYet']}
                                        </p>
                                        <div className="flex">
                                            <a href={generateUrl('sitzkriegerhealthscore_redirect_redirect')} className="text-sm font-semibold leading-6 text-white rounded bg-[#00537e] px-2 py-1 shadow-sm hover:text-[#00537e] hover:bg-[#00537e]/[.08]">
                                                {i18n['profile.healthScore.startTestNow']}
                                            </a>
                                        </div>
                                    </div>
                                </div>) : data ? (<HealthScoreDoughnutChart color={data.scores.healthScoreColor} score={data.scores.healthScore}/>) : (<></>)}
                        </div>

                        <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                            <div className="text-sm flex justify-between items-center">
                                {notFound ? (<div></div>) : data ? (<a href={generateUrl('sitzkrieger_health_score', { record: data.token })} className="text-sm font-medium leading-6 text-[#00537e]">
                                        {i18n['profile.healthScore.showReport']}
                                    </a>) : (<div></div>)}
                                <a href={generateUrl('sitzkriegerhealthscore_redirect_redirect')} className="text-sm font-semibold leading-6 text-[#00537e] rounded bg-[#00537e]/[.08] px-2 py-1 shadow-sm hover:text-white hover:bg-[#00537e]">
                                    {i18n['profile.healthScore.startTest']}
                                </a>
                            </div>
                        </div>
                    </>)}
            </>)}
        
    </div>;
};
ProfileHealthScoreTile.displayName = 'ProfileHealthScoreTile';
export default ProfileHealthScoreTile;
