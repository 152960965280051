import * as React from 'react';
import PostCard from './PostCard';
// @ts-ignore
import { TabPanel, Tabs, TabList, Tab } from 'react-tabs';
import PlayIcon from './PlayIcon';
const ConfiguratorTab = (props) => {
    const showTabList = (props.postCards.length > 0 && props.postCards[0].type === '1') || (props.postCards.length > 1 && props.postCards[0].type === '2');
    return <Tabs className={props.className} selectedTabClassName="sitzkrieger-collection__tabs__tabButton--selected">
        {props.postCards.map((postCard) => <React.Fragment key={postCard.id}>
            <TabPanel key={postCard.id}>
                <PostCard postCard={postCard} updateModalTitle={props.updateModalTitle}/>
            </TabPanel>
        </React.Fragment>)}
        <TabList style={{ display: showTabList ? 'flex' : 'none' }}>
            {props.postCards.map((postCard) => <>
                <Tab key={postCard.id} className="sitzkrieger-collection__tabs__tabButton">
                    <img src={postCard.frontSrc} onError={({ target }) => {
                // @ts-ignore
                target.onerror = null;
                // @ts-ignore
                target.src = '';
                if (postCard.video?.type === 'youtube') {
                    // @ts-ignore
                    target.src = 'https://img.youtube.com/vi/' + postCard.video.video + '/mqdefault.jpg';
                }
            }} alt={postCard.title} className="sitzkrieger-collection__tabs__image"/>
                    <PlayIcon style={{ position: 'absolute', color: 'white' }}/>
                </Tab>
            </>)}
        </TabList>
    </Tabs>;
};
ConfiguratorTab.displayName = 'ConfiguratorTab';
export default ConfiguratorTab;
