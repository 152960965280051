import * as React from 'react';
const BallIcon = () => {
    return <>
        <svg id="BG" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 992.54 992.54" fill="currentColor">
            <circle cx="496.27" cy="496.27" r="290.67"/>
            <path stroke="#000" strokeMiterlimit="10" strokeWidth="7px" d="m714.27,545.6c-4.93,22.29-18.54,69.7-58.67,114.67-40.96,45.9-87.67,64.76-109.33,72"/>
        </svg>
    </>;
};
BallIcon.displayName = 'BallIcon';
export default BallIcon;
