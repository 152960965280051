import * as React from 'react';
import { useEffect, useState } from 'react';
import { getCommentsForElement, voteOnComment } from '../../Utilities/api';
import parse from 'html-react-parser';
import { toast } from 'react-hot-toast';
const Comment = ({ comment, hasPermission }) => {
    const [reaction, setReaction] = useState(comment.currentUserReaction);
    const [reactionCount, setReactionCount] = useState({ up: comment.up, down: comment.down });
    const vote = (newReaction) => {
        if (!hasPermission) {
            toast.error('Du musst angemeldet sein, um auf Kommentare zu reagieren.');
            return;
        }
        if (reaction === 'up' || reaction === 'down') {
            return;
        }
        voteOnComment(comment.id, newReaction).then((res) => {
            if (!res.success) {
                toast.error('Ups. Da hat etwas nicht geklappt - versuch es später nochmal.');
                return;
            }
            setReaction(newReaction);
            if (newReaction === 'up') {
                setReactionCount({ up: (Number(reactionCount.up) + 1), down: reactionCount.down });
            }
            else {
                setReactionCount({ up: reactionCount.up, down: (Number(reactionCount.down) + 1) });
            }
        });
    };
    return <div className="flex my-8">
        <div className="flex-shrink-0 mr-3">
            {comment.profile !== null ? <>
                {parse(comment.profile)}
            </> : <>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-20 w-20 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clipRule="evenodd"/>
                </svg></>}
        </div>
        <div className="flex flex-col text-xl font-light" title="{{ item.creationDate|date }}">
            <b className="text-2xl">{comment.username}</b>
            <p>{comment.comment}</p>
            <div className='flex mt-3'>
                <div className='flex items-center'>
                    {reaction === 'up' ? <svg xmlns="http://www.w3.org/2000/svg" className="h-9 w-9 text-brand-green1" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z"/>
                    </svg> : <svg onClick={() => vote('up')} xmlns="http://www.w3.org/2000/svg" className={'h-9 w-9 text-brand-green1' + (reaction === 'down' ? '' : ' cursor-pointer')} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5"/>
                    </svg>}
                    <span className='pl-1 pr-3'>{reactionCount.up}</span>
                </div>
                <div className='flex items-center'>
                    {reaction === 'down' ?
            <svg xmlns="http://www.w3.org/2000/svg" className="h-9 w-9 text-brand-red1" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M18 9.5a1.5 1.5 0 11-3 0v-6a1.5 1.5 0 013 0v6zM14 9.667v-5.43a2 2 0 00-1.105-1.79l-.05-.025A4 4 0 0011.055 2H5.64a2 2 0 00-1.962 1.608l-1.2 6A2 2 0 004.44 12H8v4a2 2 0 002 2 1 1 0 001-1v-.667a4 4 0 01.8-2.4l1.4-1.866a4 4 0 00.8-2.4z"/>
                        </svg> : <svg onClick={() => vote('down')} xmlns="http://www.w3.org/2000/svg" className={'h-9 w-9 text-brand-red1' + (reaction === 'up' ? '' : ' cursor-pointer')} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M10 14H5.236a2 2 0 01-1.789-2.894l3.5-7A2 2 0 018.736 3h4.018a2 2 0 01.485.06l3.76.94m-7 10v5a2 2 0 002 2h.096c.5 0 .905-.405.905-.904 0-.715.211-1.413.608-2.008L17 13V4m-7 10h2m5-10h2a2 2 0 012 2v6a2 2 0 01-2 2h-2.5"/>
                        </svg>}
                    <span className='pl-1 pr-3'>{reactionCount.down}</span>
                </div>
            </div>
        </div>
    </div>;
};
Comment.displayName = 'Comment';
const CommentList = ({ type, uid }) => {
    const [comments, setComments] = useState([]);
    const [hasPermission, setHasPermission] = useState(false);
    const reloadComments = () => {
        getCommentsForElement(type, uid).then((result) => {
            setComments(result.comments);
            setHasPermission(result.canComment || false);
        });
    };
    useEffect(() => reloadComments(), []);
    document.addEventListener('comment-sent-event', () => reloadComments());
    return <>{comments.map((el) => <Comment key={el.id} comment={el} hasPermission={hasPermission}/>)}</>;
};
CommentList.displayName = 'CommentList';
export default CommentList;
