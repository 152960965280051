import BaseElement from './BaseElement';
import ProfileStats from '../Components/Profile/ProfileStats';
class ProfileStatsElement extends BaseElement {
    constructor() {
        super(...arguments);
        this.element = ProfileStats;
    }
}
export const loadProfileStatsElementWebComponent = () => {
    customElements.define('profile-stats', ProfileStatsElement);
};
