import * as React from 'react';
import ReactModal from 'react-modal';
const customStylesRoundedBorder = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        zIndex: 99999,
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: '0',
        width: '100%',
        maxWidth: '650px',
        minHeight: '350px',
        maxHeight: '100%',
        border: '',
        borderRadius: '30px',
    }
};
export const ModalHeader = (props) => {
    return <header className={'flex flex-row bg-brand-blue1 relative ' + (props.slim ? 'p-2' : 'p-8')}>
        <div className="w-full text-center z-10">
            <h1 className={'font-bold text-white text-3xl'}>{props.title}</h1>
        </div>
        <svg onClick={() => props.onClick()} xmlns="http://www.w3.org/2000/svg" className={'h-6 w-6 text-white cursor-pointer z-10'} fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12"/>
        </svg>
    </header>;
};
const Modal = (props) => {
    return <ReactModal isOpen={props.modalOpen} onRequestClose={() => props.closeModal()} contentLabel={'Modal'} style={customStylesRoundedBorder}>
        <section>
            {props.customHeader ? props.customHeader : <ModalHeader onClick={props.closeModal} title={props.modalHeader || ''}/>}
            <main className='text-center p-4'>
                {props.children}
            </main>
            {props.footer && <footer className={'absolute bottom-4 flex justify-around w-full'}>
                {props.footer}
            </footer>}
        </section>
    </ReactModal>;
};
Modal.displayName = 'Modal';
export default Modal;
