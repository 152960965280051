import * as React from 'react';
import { useEffect, useState } from 'react';
import { toSeconds } from './utils';
import { Tooltip } from 'react-tooltip';
import Icon from './Icon';
const VideoChapters = ({ videoChapters, handleSeekTo, playedSeconds, }) => {
    const [, setCurrentChapter] = useState(videoChapters[0]);
    useEffect(() => {
        videoChapters.forEach((videoChapter) => {
            const chapterStartsAt = toSeconds(videoChapter.time);
            const chapterEndsAt = videoChapters[videoChapter.id + 1] !== undefined ? toSeconds(videoChapters[videoChapter.id + 1].time) - 1 : null;
            if (playedSeconds >= chapterStartsAt && playedSeconds <= (chapterEndsAt || 0)) {
                setCurrentChapter(videoChapter);
            }
        });
    }, [videoChapters, playedSeconds]);
    const firstChapterStartsAtZero = videoChapters[0] && toSeconds(videoChapters[0].time) === 0;
    return <div className="rounded-b-lg bg-gray-100 shadow p-4">
        <ol className="flex items-center w-full">
            {!firstChapterStartsAtZero && (<li className={'flex w-full items-center after:content-[\'\'] after:w-full after:h-1 after:border-b after:border-4 after:inline-block ' + (playedSeconds > 0 ? ' after:border-emerald-600 ' : ' after:border-gray-300 ')} key="start">
                    <span className={'flex items-center justify-center w-10 h-10 rounded-full lg:h-8 lg:w-8 shrink-0 ' + (playedSeconds > 0 ? 'bg-emerald-600 ring-emerald-600 text-white ' : ' bg-gray-300 ring-gray-300 text-gray-300 ')} data-tooltip-id={'start'} data-tooltip-content={'Start'}>
                        <i className="fa-solid text-white fa-play"></i>
                    </span>
                    <Tooltip id={'start'}/>
                </li>)}
            
            {videoChapters.map(({ id, title, time, typeIcon, iconIsLocal }) => <li className={'flex w-full items-center after:content-[\'\'] after:w-full after:h-1 after:border-b after:border-4 after:inline-block ' + (playedSeconds >= toSeconds(time) ? ' after:border-emerald-600 ' : ' after:border-gray-300 ')} key={id}>
                    <button onClick={handleSeekTo} value={toSeconds(time)} data-tooltip-id={id.toString()} data-tooltip-content={title} data-tooltip-place="top" className={'flex items-center justify-center rounded-full h-8 w-8 ring-2 z-10 shrink-0 ring-gray-300 ' + (playedSeconds >= toSeconds(time) ? 'bg-emerald-600 ring-emerald-600 text-white ' : ' text-gray-300 ')}>
                        {iconIsLocal
                ? <Icon iconName={typeIcon}></Icon>
                : <i className={`w-4 h-4 ${typeIcon}`}></i>}

                    </button>
                    <Tooltip id={id.toString()}/>
                </li>)}

            <li className="flex items-center" key="end">
                <span className="flex items-center justify-center w-10 h-10 rounded-full lg:h-8 lg:w-8 bg-gray-300 shrink-0" data-tooltip-id={'end'} data-tooltip-content={'Ende'}>
                    <i className="w-4 h-4 fas text-white fa-check"></i>
                </span>
                <Tooltip id={'end'}/>
            </li>
        </ol>
    </div>;
};
VideoChapters.displayName = 'VideoChapters';
export default VideoChapters;
