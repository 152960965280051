import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateCourseProgress } from '../../../../Utilities/api';
export const useUpdateCourseProgress = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ courseUuid, lectureUuid }) => updateCourseProgress(courseUuid, lectureUuid),
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({ queryKey: ['courseProgress', variables.courseUuid] });
            return data;
        },
    });
};
