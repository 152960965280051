import * as React from 'react';
import { useEffect, useState } from 'react';
import Modal from './Modal';
import { Controller, useForm } from 'react-hook-form';
import { generateUrl } from '../../Utilities/api';
import * as ReactModal from 'react-modal';
import BaseElement from '../CustomElements/BaseElement';
import { RadioGroup } from '@headlessui/react';
const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ');
};
const CheckboxFields = (props) => {
    const [showOther, setShowOther] = useState(props.options.some((i) => i.field === props.value));
    const [otherValue, setOtherValue] = useState('');
    const [selected, setSelected] = useState([]);
    useEffect(() => {
        props.onChange(selected.join(','));
    }, [selected]);
    useEffect(() => {
        if (otherValue !== '' && showOther) {
            addSelection(otherValue);
        }
    }, [otherValue]);
    const setOthersInactive = () => {
        setShowOther(false);
        if (otherValue !== '') {
            removeSelection(otherValue);
        }
    };
    const setOthersActive = () => {
        setShowOther(true);
        if (otherValue !== '') {
            addSelection(otherValue);
        }
    };
    const handleOthersChange = (value) => {
        if (otherValue !== '') {
            removeSelection(otherValue);
        }
        setOtherValue(value);
    };
    const addSelection = (value) => {
        const selection = [
            ...selected,
            value
        ];
        setSelected(selection);
    };
    const removeSelection = (value) => {
        const array = [...selected];
        const index = array.indexOf(value);
        if (index !== -1) {
            array.splice(index, 1);
            setSelected(array);
        }
    };
    const isChecked = (value) => {
        return selected.indexOf(value) >= 0;
    };
    return <fieldset>
        <div className="-space-y-px rounded-md bg-white">
            {props.options.map((item, i) => (<div key={'item' + i} className={classNames(i === 0 ? 'rounded-tl-sm rounded-tr-sm' : '', isChecked(item.field) ? 'border-brand-primary bg-blue-50' : 'border-gray-200', 'z-20 relative flex cursor-pointer border p-4 focus:outline-none')} data-value={item.field} onClick={() => !isChecked(item.field) ? addSelection(item.field) : removeSelection(item.field)}>
                    <div className="flex h-6 items-center">
                        <input id={`filter-${props.id}-${i}`} name={`${props.id}[]`} type="checkbox" defaultValue={item.field} checked={isChecked(item.field)} onChange={() => !isChecked(item.field) ? addSelection(item.field) : removeSelection(item.field)} className="h-4 w-4 rounded border-gray-300 text-brand-primary focus:ring-brand-primary"/>
                    </div>
                    <div className="ml-3 text-sm leading-6 pointer-events-none">
                        <label htmlFor={`filter-${props.id}-${i}`} className={classNames(isChecked(item.field) ? 'text-brand-primary' : 'text-gray-900', ' font-medium pointer-events-none')}>
                            {item.label}
                        </label>
                    </div>
                </div>))}
            {props.withOthers && <div key={'itemOthers' + props.id} className="relative flex items-center">
                <div className={classNames(showOther ? 'border-brand-primary bg-blue-50' : 'border-gray-200', 'z-10 rounded-bl-sm w-full rounded-br-sm relative flex items-center cursor-pointer border px-4 py-2.5 focus:outline-none')}>
                    <div className="flex h-6 items-center">
                        <input type="checkbox" checked={showOther} onChange={setOthersInactive} className="h-4 w-4 rounded border-gray-300 text-brand-primary focus:ring-brand-primary"/>
                    </div>
                    <div className="ml-3 w-full text-sm leading-6">
                        <div className="relative">
                            <input type="text" className={classNames(showOther ? 'bg-blue-50' : '', 'block w-full border-0 ring-0 p-1.5 text-gray-900 sm:text-sm sm:leading-6')} placeholder="Sonstiges" onClick={setOthersActive} onChange={(event) => handleOthersChange(event.target.value)}/>
                            <div className="absolute inset-x-0 bottom-0 border-t border-gray-300" aria-hidden="true"/>
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    </fieldset>;
};
const RadioInputFields = (props) => {
    const [otherValue, setOtherValue] = useState('');
    const [selected, setSelected] = useState('');
    useEffect(() => {
        props.onChange(selected);
    }, [selected]);
    const handleOthersChange = (value) => {
        setOtherValue(value);
        setSelected(value);
    };
    return <RadioGroup value={selected} onChange={setSelected}>
        <div className="-space-y-px rounded-md bg-white">
            {props.options.map((item, i) => (<RadioGroup.Option key={'item' + i} value={item.field} className={({ checked }) => classNames(i === 0 ? 'rounded-tl-sm rounded-tr-sm' : '', i === props.options.length - 1 ? 'rounded-bl-sm rounded-br-sm' : '', checked ? 'z-10 border-brand-primary bg-blue-50' : 'border-gray-200', 'relative flex cursor-pointer border p-4 focus:outline-none')}>
                    {({ active, checked }) => (<>
                            <span className={classNames(checked ? 'bg-brand-primary border-transparent' : 'bg-white border-gray-300', active ? 'ring-2 ring-offset-2 ring-brand-primary' : '', 'mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center')} aria-hidden="true">
                                <span className="rounded-full bg-white w-1.5 h-1.5"/>
                            </span>
                            <span className="ml-3 flex flex-col">
                                <RadioGroup.Label as="span" className={classNames(checked ? 'text-brand-primary' : 'text-gray-900', 'block text-sm font-medium')}>
                                    {item.label}
                                </RadioGroup.Label>
                            </span>
                        </>)}
                </RadioGroup.Option>))}
            {props.withOthers && <RadioGroup.Option key={'itemOthers' + props.id} value={(otherValue === '' ? '-1' : otherValue)} className={({ checked }) => classNames(checked ? 'z-10 border-brand-primary bg-blue-50' : 'border-gray-200', 'rounded-bl-sm rounded-br-sm relative flex items-center cursor-pointer border px-4 py-2.5 focus:outline-none')}>
                {({ active, checked }) => (<>
                        <span className={classNames(checked ? 'bg-brand-primary border-transparent' : 'bg-white border-gray-300', active ? 'ring-2 ring-offset-2 ring-brand-primary' : '', 'mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center')} aria-hidden="true">
                            <span className="rounded-full bg-white w-1.5 h-1.5"/>
                        </span>
                        <span className="ml-3 w-full flex flex-col">
                            <RadioGroup.Label as="div" className={classNames(checked ? 'text-brand-primary' : 'text-gray-900', 'block text-sm font-medium')}>
                                <div className="ml-3 w-full text-sm leading-6">
                                    <div className="relative">
                                        <input type="text" className={classNames(checked ? 'bg-blue-50' : '', 'block w-full border-0 ring-0 p-1.5 text-gray-900 sm:text-sm sm:leading-6')} onChange={(event) => handleOthersChange(event.target.value)} placeholder="Sonstiges"/>
                                        <div className="absolute inset-x-0 bottom-0 border-t border-gray-300" aria-hidden="true"/>
                                    </div>
                                </div>
                            </RadioGroup.Label>
                        </span>
                    </>)}
            </RadioGroup.Option>}
        </div>
    </RadioGroup>;
};
var OnboardingLoadingState;
(function (OnboardingLoadingState) {
    OnboardingLoadingState["loading"] = "loading";
    OnboardingLoadingState["open"] = "open";
    OnboardingLoadingState["aborted"] = "aborted";
    OnboardingLoadingState["done"] = "done";
})(OnboardingLoadingState || (OnboardingLoadingState = {}));
const OnboardingModal = (props) => {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [showHealthScoreButton,] = useState(false);
    const [settings, setSettings] = useState({
        healthscore: false,
        onboarding: false,
        state: OnboardingLoadingState.loading,
    });
    const { register, handleSubmit, control } = useForm();
    const onSubmit = data => {
        fetch(generateUrl('app_myarea_updateuserinformation'), {
            method: 'POST',
            credentials: 'same-origin',
            body: JSON.stringify(data),
        })
            .then(val => val.json())
            .then(val => {
            if (val.success === true) {
                if (settings.healthscore) {
                    // setShowHealthScoreButton(true);
                }
                else {
                    setSettings({
                        ...settings,
                        state: OnboardingLoadingState.done
                    });
                    setIsOpen(false);
                }
            }
        });
    };
    const fetchSettings = () => {
        fetch(generateUrl('app_myarea_onboarding_settings'), {
            method: 'GET',
            credentials: 'same-origin'
        })
            .then(val => val.json())
            .then((val) => {
            setSettings({
                ...val,
                state: OnboardingLoadingState.open
            });
        });
    };
    useEffect(() => {
        if (settings.state === OnboardingLoadingState.loading) {
            fetchSettings();
        }
    }, [modalIsOpen, settings]);
    useEffect(() => {
        if (settings.state === OnboardingLoadingState.open && !modalIsOpen) {
            openModal();
        }
        //if (modalIsOpen && showHealthScoreButton === false && settings.healthscore && !settings.onboarding) {
        //    setShowHealthScoreButton(true);
        //}
        if (modalIsOpen && showHealthScoreButton === false && !settings.healthscore && !settings.onboarding) {
            closeModal();
        }
    }, [settings, modalIsOpen, showHealthScoreButton]);
    function openModal() {
        setIsOpen(true);
    }
    function closeModal() {
        setIsOpen(false);
        setSettings({
            ...settings,
            state: OnboardingLoadingState.aborted
        });
    }
    return <Modal modalOpen={modalIsOpen} closeModal={closeModal} modalHeader={showHealthScoreButton ? 'Starte jetzt mit dem Health Score' : 'Vervollständige jetzt deine Registrierung'} footer={<div className="mt-3">
            {showHealthScoreButton && <a href={generateUrl('sitzkriegerhealthscore_redirect_redirect')} className="m-auto py-3 px-6 inline-flex justify-center items-center gap-2 rounded-full border border-transparent font-semibold bg-brand-secondary text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm">
                HealthScore Fragebogen starten
            </a>}
        </div>}>
        <>
            {showHealthScoreButton && <div className={'mt-3 text-left'}>
                <p>
                    Du willst wissen, wie es um deine Gesundheit steht, wo deine Potenziale liegen und welche
                    Sitzkrieger-Inhalte perfekt zu dir passen? Unser aussagekräftiger Gesundheitscheck hilft dir deine
                    Stärken und Potenziale besser einzuschätzen. Im Anschluss erhältst du einen Gesundheitsbericht, der
                    die sofort Empfehlungen für deine persönlichen Bedürfnisse gibt, um deine Gesundheitsziele Schritt
                    für Schritt zu erreichen.
                </p>
            </div>}
            {!showHealthScoreButton && <>
                <div className='mt-3 text-left'>
                    {settings.onboarding && <>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="my-8">
                                <label htmlFor="input-birthday" className="mb-4 text-base font-semibold leading-6 text-gray-900">Geburtsdatum</label>
                                <div className="mt-4">
                                    <input type="date" required={false} id="input-birthday" className="py-3 px-4 block w-full border border-gray-200 shadow-sm rounded-full text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500" {...register('birthday', { required: false })}/>
                                </div>
                            </div>
                            <div className="my-8">
                                <label htmlFor="input-gender" className="mb-4 text-base font-semibold leading-6 text-gray-900">Geschlecht</label>
                                <div className="mt-4">
                                    <select id="input-gender" required={false} className={'py-3 px-4 pr-9 block w-full border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500'} {...register('gender')}>
                                        <option value="m">Männlich</option>
                                        <option value="f">Weiblich</option>
                                        <option value="d">Divers</option>
                                    </select>
                                </div>
                            </div>
                            {props.showSitzkriegerCourseQuestions ? <></> : <>
                                <div className="my-8">
                                    <label className="mb-4 text-base font-semibold leading-6 text-gray-900">Mein Ziel
                                        mit Sitzkrieger</label>
                                    <div className="mt-4">
                                        <Controller render={({ field }) => {
                        return <CheckboxFields id="trainingGoal" onChange={field.onChange} options={[
                                {
                                    field: 'schmerzfrei bewegen',
                                    label: 'schmerzfrei bewegen'
                                },
                                {
                                    field: 'gesünder Arbeiten',
                                    label: 'gesünder Arbeiten'
                                },
                                {
                                    field: 'Beweglichkeit verbessern',
                                    label: 'Beweglichkeit verbessern'
                                },
                                {
                                    field: 'das Sitzen ausgleichen',
                                    label: 'das Sitzen ausgleichen'
                                },
                                {
                                    field: 'den Körper kräftigen',
                                    label: 'den Körper kräftigen'
                                },
                            ]} value={field.value} withOthers={true}/>;
                    }} control={control} name="trainingGoal"/>
                                    </div>
                                </div>
                            </>}
                            <div className="my-8">
                                <label className="mb-4 text-base font-semibold leading-6 text-gray-900">Ich habe
                                    Bewegungsmangel und würde mich gerne mehr und gesund bewegen</label>
                                <div className="mt-4">
                                    <Controller render={({ field }) => {
                    return <RadioInputFields id="moreMovement" onChange={(v) => field.onChange(v === '1')} options={[
                            {
                                field: '1',
                                label: 'Ja'
                            },
                            {
                                field: '0',
                                label: 'Nein'
                            },
                        ]} value={typeof field.value === 'undefined' ? '' : (field.value === true ? '1' : '0')} withOthers={false}/>;
                }} control={control} name="moreMovement"/>
                                </div>
                            </div>
                            {props.showSitzkriegerCourseQuestions ? <></> : <>
                                <div className="my-8">
                                    <label className="mb-4 text-base font-semibold leading-6 text-gray-900">Ich traue
                                        mir zu
                                        pro Woche … Einheiten gesunde Bewegung in meinen Alltag einzubauen</label>
                                    <div className="mt-4">
                                        <Controller render={({ field }) => {
                        return <RadioInputFields id="workoutsPerWeek" onChange={(v) => field.onChange(Number(v))} options={[
                                {
                                    field: '0',
                                    label: '0'
                                },
                                {
                                    field: '1',
                                    label: '1'
                                },
                                {
                                    field: '2',
                                    label: '2'
                                },
                                {
                                    field: '3',
                                    label: '3'
                                },
                                {
                                    field: '4',
                                    label: '4'
                                },
                                {
                                    field: '5',
                                    label: '5'
                                },
                                {
                                    field: '6',
                                    label: '6'
                                },
                                {
                                    field: '7',
                                    label: '7'
                                },
                            ]} value={String(field.value)} withOthers={false}/>;
                    }} control={control} name="workoutsPerWeek"/>
                                    </div>
                                </div>
                            </>}
                            {props.showSitzkriegerCourseQuestions ? <></> : <>
                                <div className="my-8">
                                    <label className="mb-4 text-base font-semibold leading-6 text-gray-900">Pro Einheit
                                        kann
                                        ich … Minuten Zeit investieren</label>
                                    <div className="mt-4">
                                        <Controller render={({ field }) => {
                        return <RadioInputFields id="workoutDuration" onChange={(v) => field.onChange(Number(v))} options={[
                                {
                                    field: '5',
                                    label: '5'
                                },
                                {
                                    field: '10',
                                    label: '10'
                                },
                                {
                                    field: '15',
                                    label: '15'
                                },
                                {
                                    field: '30',
                                    label: '30'
                                },
                                {
                                    field: '45',
                                    label: '45'
                                },
                                {
                                    field: '60',
                                    label: 'mehr als 45'
                                },
                            ]} value={String(field.value)} withOthers={false}/>;
                    }} control={control} name="workoutDuration"/>
                                    </div>
                                </div>
                            </>}
                            {props.sourceMarketingFields && props.sourceMarketingFields.length > 0 ? <>
                                <div className="my-8">
                                    <label htmlFor="input-sourceMarketing" className="mb-4 text-base font-semibold leading-6 text-gray-900">Ich bin auf
                                        Sitzkrieger aufmerksam geworden über</label>
                                    <div className="mt-4">
                                        <Controller render={({ field }) => {
                        return <RadioInputFields id="sourceMarketing" onChange={field.onChange} options={props.sourceMarketingFields} value={field.value} withOthers={true}/>;
                    }} control={control} name="sourceMarketing"/>
                                    </div>
                                </div>
                            </> : <></>}
                            {props.enableTrainingsinselQuestion === true && <>
                                <div className="my-8">
                                    <label htmlFor="input-usesTrainingsinsel" className="mb-4 text-base font-semibold leading-6 text-gray-900">Meine
                                        Teilnahme an der Trainingsinsel</label>
                                    <div className="mt-4">
                                        <Controller render={({ field }) => {
                        return <RadioInputFields id="usesTrainingsinsel" onChange={field.onChange} options={[
                                {
                                    field: 'yes',
                                    label: 'Ich nehme aktuell bei der Trainingsinsel teil.'
                                },
                                {
                                    field: 'in_the_past',
                                    label: 'Ich habe in der Vergangenheit bei der Trainingsinsel teilgenommen.'
                                },
                                {
                                    field: 'no',
                                    label: 'Ich habe noch nie bei der Trainingsinsel teilgenommen'
                                },
                            ]} value={field.value} withOthers={false}/>;
                    }} control={control} name="usesTrainingsinsel"/>
                                    </div>
                                </div>
                            </>}
                            {props.showSitzkriegerCourseQuestions ? <></> : <>
                                <div className="my-8">
                                    <label htmlFor="input-emailReminder" className="text-base font-semibold leading-6 text-gray-900">Gerne lasse ich
                                        mich
                                        an Sitzkrieger einmal die Woche per Mail erinnern</label>
                                    <div className="mt-4">
                                        <Controller render={({ field }) => {
                        return <RadioInputFields id="emailReminder" onChange={field.onChange} options={[
                                {
                                    field: 'weekly',
                                    label: 'Ja'
                                },
                                {
                                    field: 'never',
                                    label: 'Nein'
                                },
                            ]} value={field.value} withOthers={false}/>;
                    }} control={control} name="emailReminder"/>
                                    </div>
                                </div>
                            </>}
                            <div className="mt-5 sm:mt-6 px-4">
                                <button type="submit" className="m-auto py-3 px-6 inline-flex w-full justify-center items-center gap-2 rounded-full border border-transparent font-semibold bg-brand-secondary text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-base">
                                    Speichern
                                </button>
                            </div>
                        </form>
                    </>}
                </div>
            </>}
        </>
    </Modal>;
};
OnboardingModal.displayName = 'OnboardingModal';
class OnboardingModalElement extends BaseElement {
    constructor() {
        super();
        this.element = OnboardingModal;
        // @ts-ignore
        ReactModal.setAppElement(this);
    }
}
export const loadOnboardingModal = () => {
    customElements.define('onboarding-modal', OnboardingModalElement);
};
