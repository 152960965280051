const infoboxToggle = () => {
    document.querySelectorAll('.js-toggle-info-box').forEach((node) => {
        const svg = node.querySelector('.js-toggle-info-box-switch');
        const element = node.querySelector('.js-toggle-info-box-element');
        if (svg) {
            svg.addEventListener('click', () => {
                element.classList.toggle('max-h-0');
            });
        }
    });
};
export default infoboxToggle;
