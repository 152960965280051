import * as React from 'react';
import BaseElement from '../../CustomElements/BaseElement';
import { useState } from 'react';
import { Switch } from '@headlessui/react';
import { subscribeToNotification } from '../../../Utilities/api';
const NotificationSubscriptionToggle = ({ active, notificationKey, title }) => {
    const [enabled, setEnabled] = useState(active);
    const [isLoading, setIsLoading] = useState(false);
    const subscribeAction = async (checked) => {
        if (isLoading) {
            return;
        }
        setEnabled(checked);
        setIsLoading(true);
        const result = await subscribeToNotification(notificationKey, enabled);
        if (result.success !== true) {
            setEnabled(!checked);
        }
        setIsLoading(false);
    };
    return <Switch checked={enabled} onChange={(checked) => void subscribeAction(checked)} className={`${enabled ? 'bg-brand-primary' : 'bg-gray-200'} relative inline-flex h-6 w-11 items-center rounded-full`}>
        <span className="sr-only">{title}</span>
        <span className={`${enabled ? 'translate-x-6' : 'translate-x-1'} inline-block h-4 w-4 transform rounded-full bg-white transition`}/>
    </Switch>;
};
class NotificationSubscriptionToggleElement extends BaseElement {
    constructor() {
        super(...arguments);
        this.element = NotificationSubscriptionToggle;
    }
}
export const loadNotificationSubscriptionToggle = () => {
    customElements.define('notification-subscription-toggle', NotificationSubscriptionToggleElement);
};
