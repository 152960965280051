import * as React from 'react';
import { useEffect, useState } from 'react';
import BaseElement from '../../CustomElements/BaseElement';
import CourseLectureItem from './CourseLectureItem';
import Spinner from '../Spinner';
import { useI18n } from '../../../Translation';
import { useCourse } from './Hooks/useCourse';
import { useLecture } from './Hooks/useLecture';
import CourseLectureProgressButton from './CourseLectureProgressButton';
import { useCourseProgress } from './Hooks/useCourseProgess';
const CourseLectureItemList = (props) => {
    const { isLoading: isCourseProgressLoading, data: courseProgress } = useCourseProgress(props.courseUuid);
    const { isLoading: courseIsLoading, data: course } = useCourse(props.courseUuid);
    const { isLoading: lectureIsLoading, data: lecture } = useLecture(props.courseUuid, props.moduleUuid, props.lectureUuid);
    const [isFinished, setIsFinished] = useState(false);
    const i18n = useI18n();
    useEffect(() => {
        if (!isCourseProgressLoading && courseProgress && courseProgress.progress) {
            const moduleProgress = courseProgress.progress.perModules[props.moduleUuid];
            if (moduleProgress) {
                const { finishedLectures } = moduleProgress;
                setIsFinished(finishedLectures.includes(props.lectureUuid));
            }
        }
    }, [isCourseProgressLoading, courseProgress, props.moduleUuid, props.lectureUuid]);
    if (courseIsLoading || lectureIsLoading) {
        return <Spinner />;
    }
    if (!lecture) {
        return <div className="error-message">{i18n['course.lectureNotFound']}</div>;
    }
    if (!course) {
        return <div className="error-message">{i18n['course.courseNotFound']}</div>;
    }
    return <>
        <div className="hidden sm:flex">
            <ol role="list" className="flex items-center space-x-4">
                <li>
                    <div className="flex">
                        <a href={course.pageLink} className="text-sm font-medium text-gray-400 hover:text-gray-500">
                            {course.title}
                        </a>
                    </div>
                </li>
                <li>
                    <div className="flex items-center">
                        <i className="fa-regular fa-chevron-right fa-xs text-gray-400 flex-shrink-0"></i>
                        <span className="ml-4 text-sm font-medium text-gray-500">
                            {lecture.title}
                        </span>
                    </div>
                </li>
            </ol>
        </div>
        <h2 className="mt-2 mb-8 text-3xl font-bold leading-7 text-gray-900 sm:text-4xl sm:tracking-tight">
            {lecture.title}
        </h2>
        <p className="mb-4 text-lg leading-8 text-gray-600">
            {lecture.subtitle}
        </p>
        <div className="space-y-8">
            {lecture.courseLectureItems.map((courseLectureItem, index) => (<CourseLectureItem key={index} courseLectureItem={courseLectureItem} open={index <= 0}/>))}

            {!isFinished ? (<li key="lecture-end-item" className="relative flex gap-x-4">
                    <div className="absolute left-0 top-0 flex w-6 justify-center -bottom-6">
                        <div className="w-px bg-gray-200"></div>
                    </div>
                    <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                        <div className="h-3 w-3 rounded-full bg-gray-200"></div>
                    </div>

                    <CourseLectureProgressButton courseUuid={props.courseUuid} lectureUuid={props.lectureUuid}/>
                </li>) : (<li key="lecture-end-item" className="relative flex gap-x-4">
                    <div className="absolute left-0 top-0 flex w-6 justify-center -bottom-6">
                        <div className="w-px bg-gray-200"></div>
                    </div>
                    <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                        <div className="h-3 w-3 rounded-full bg-gray-200"></div>
                    </div>

                    <button type="button" className="inline-flex items-center gap-x-2 rounded-md bg-brand-secondary px-3.5 py-2.5 text-base font-semibold text-white shadow-sm md:bottom-24 md:right-12">
                        {i18n['course.finishedLecture']}
                    </button>
                </li>)}

            <li key="lecture-next-item" className="relative flex gap-x-4">
                <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                    <div className="h-3 w-3 rounded-full bg-gray-200"></div>
                </div>
                <a href={course.pageLink} className="inline-flex items-center gap-x-2 rounded-md bg-gray-100 px-3.5 py-2.5 text-base font-semibold text-gray-900 shadow-sm md:bottom-24 md:right-12">
                    {i18n['course.backToOverview']}
                </a>
            </li>
        </div>
    </>;
};
CourseLectureItemList.displayName = 'LectureItemList';
export default CourseLectureItemList;
class CourseLectureItemListElement extends BaseElement {
    constructor() {
        super(...arguments);
        this.element = CourseLectureItemList;
    }
}
export const loadCourseLectureItemListElement = () => {
    customElements.define('lecture-item-list', CourseLectureItemListElement);
};
