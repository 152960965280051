import * as React from 'react';
import { useState, useEffect } from 'react';
// @ts-ignore
import ReactSlider from 'react-slider';
const PriceUserAmountSlider = () => {
    const [amount, setAmount] = useState(1);
    const priceBasic = document.querySelector('[data-price-basic]')?.dataset?.priceBasic || '';
    const pricePremium = document.querySelector('[data-price-premium]')?.dataset?.pricePremium || '';
    const priceEnterprise = document.querySelector('[data-price-enterprise]')?.dataset?.priceEnterprise || '';
    let priceBasicInt = 0;
    let pricePremiumInt = 0;
    let priceEnterpriseInt = 0;
    if (priceBasic !== 'na') {
        priceBasicInt = parseInt(priceBasic);
    }
    if (pricePremium !== 'na') {
        pricePremiumInt = parseInt(pricePremium);
    }
    if (priceEnterprise !== 'na') {
        priceEnterpriseInt = parseInt(priceEnterprise);
    }
    const basicPrice = document.querySelectorAll('.js-sitzkrieger-price-basic');
    const premiumPrice = document.querySelectorAll('.js-sitzkrieger-price-premium');
    const enterprisePrice = document.querySelectorAll('.js-sitzkrieger-price-enterprise');
    useEffect(() => {
        if (priceBasic !== 'na') {
            basicPrice.forEach((node) => {
                const price = ((priceBasicInt * amount)) / 100;
                node.innerText = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(price) + ' / Monat';
            });
        }
        else {
            basicPrice.forEach((node) => {
                node.innerText = 'Preis auf Anfrage';
            });
        }
        if (pricePremium !== 'na') {
            premiumPrice.forEach((node) => {
                const price = ((pricePremiumInt * amount)) / 100;
                node.innerText = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(price) + ' / Monat';
            });
        }
        else {
            premiumPrice.forEach((node) => {
                node.innerText = 'Preis auf Anfrage';
            });
        }
        if (priceEnterprise !== 'na') {
            enterprisePrice.forEach((node) => {
                const price = ((priceEnterpriseInt * amount)) / 100;
                node.innerText = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(price) + ' / Monat';
            });
        }
        else {
            enterprisePrice.forEach((node) => {
                node.innerText = 'Preis auf Anfrage';
            });
        }
    }, [amount]);
    return <>
        <h3 className='text-center text-3xl text-brand-primary font-black'>Anzahl Mitarbeiter</h3>
        <ReactSlider className="sitzkrieger-slider" thumbClassName="sitzkrieger-slider__thumb" trackClassName="sitzkrieger-slider__track" defaultValue={1} min={1} max={200} renderTrack={(props, state) => {
            setAmount(state.value);
            return <div {...props}/>;
        }}/>
        <p className='mt-12 text-center text-3xl text-brand-primary font-black'>{amount}</p>
    </>;
};
PriceUserAmountSlider.displayName = 'PriceUserAmountSlider';
export default PriceUserAmountSlider;
