import * as React from 'react';
export const ProfileStatsInfoToolTip = ({ content }) => {
    return <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block cursor-pointer">
        <span data-tooltip-id={'icon-set-tooltip'} data-tooltip-content={content}>
            <i className="fa-regular fa-circle-info"></i>
        </span>
    </div>;
};
ProfileStatsInfoToolTip.displayName = 'ProfileStatsInfoToolTip';
export default ProfileStatsInfoToolTip;
