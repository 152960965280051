import * as React from 'react';
const CalendarIconCube = () => {
    return <>
        <div className="flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-lg bg-blue-500">
            <svg className="h-7 w-7 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path fill="currentColor" d="M5 3h1V1h2v2h8V1h2v2h1c1.11 0 2 .89 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2m0 16h14V9H5v10M5 7h14V5H5v2m12 4v2H7v-2h10"/>
            </svg>
        </div>
    </>;
};
CalendarIconCube.displayName = 'CalendarIconCube';
export default CalendarIconCube;
