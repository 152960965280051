import '../Scss/index.scss';
import navigation from './Functions/navigation';
import loader from './React/loader';
import forms from './Functions/forms';
import LazyLoad from 'vanilla-lazyload';
import infoboxToggle from './Functions/infobox-toggle';
import accordion from './Functions/accordion';
import * as Sentry from '@sentry/react';
import dispatchOpenChallengeModalEvent from './Events/OpenChallengeModalEvent';
Sentry.init({
    dsn: 'https://94a21667def4424795bb3fdf41dbb118@sentry.infra.sitzkrieger.com/2',
    integrations: [
        new Sentry.BrowserTracing({
            tracePropagationTargets: [/^https:\/\/sitzkrieger\.braavos\.cben\.dev/, /^https:\/\/sitzkrieger\.com/, /^https:\/\/dev.sitzkrieger\.com/],
        }),
    ],
    tracesSampleRate: 1.0,
});
window.openChallengeModalEvent = dispatchOpenChallengeModalEvent;
document.addEventListener('DOMContentLoaded', () => {
    navigation();
    loader();
    forms();
    infoboxToggle();
    accordion();
    document.lazyLoadInstance = new LazyLoad({
    // Your custom settings go here
    });
    document.lazyLoadInstance.update();
    const containerElement = document.querySelectorAll('.sitzkrieger-module__container');
    containerElement.forEach((node) => {
        let counter = 0;
        const tuples = {};
        node.querySelectorAll('.sitzkrieger-module__box').forEach((box) => {
            tuples[counter] = [box.dataset.index || ''];
            if (counter > 0) {
                tuples[counter - 1].push(box.dataset.index || '');
            }
            counter++;
        });
        Object.values(tuples).forEach((tuple) => {
            if (tuple.length === 2) {
                const el1 = document.querySelector(`.sitzkrieger-module__box[data-index="${tuple[0]}"]`);
                const el2 = document.querySelector(`.sitzkrieger-module__box[data-index="${tuple[1]}"]`);
                if (typeof el1 !== 'undefined' && typeof el2 !== 'undefined') {
                    new window.LeaderLine(el1, el2, {
                        startSocket: 'auto',
                        endSocket: 'auto',
                        dash: true,
                        startPlug: 'square',
                        startPlugSize: 0.1,
                        endPlug: 'square',
                        endPlugSize: 0.1,
                        color: 'rgb(180, 180, 180)',
                    });
                }
            }
        });
    });
});
