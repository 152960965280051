import * as React from 'react';
import ReactPlayer from 'react-player';
import { getMediaElement } from '../../../Utilities/api';
const PostCardVideo = ({ postcard, props }) => {
    switch (postcard.video?.type) {
        case 'video':
            return <ReactPlayer className={props.className || null} url={getMediaElement(postcard.video.video, 'v')} controls={props.controls ?? false} light={props.light} width={props.width ?? '320px'} height={props.height ?? '176px'} config={{ file: { forceVideo: true } }}/>;
        case 'youtube':
            const youtubeUrl = `https://youtube.com/watch?v=${postcard.video.video}`;
            return <ReactPlayer className={props.className || null} url={youtubeUrl} controls={props.controls ?? false} light={props.light} width={props.width ?? '320px'} height={props.height ?? '176px'} config={{
                    youtube: {
                        embedOptions: {
                            host: 'https://www.youtube-nocookie.com'
                        }
                    }
                }}/>;
        default:
            return <></>;
    }
};
PostCardVideo.displayName = 'PostCardVideo';
export default PostCardVideo;
