import React, { createRef, useState } from 'react';
import { Cropper } from 'react-cropper';
import parse from 'html-react-parser';
import 'cropperjs/dist/cropper.css';
const file2Base64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result?.toString() || '');
        reader.onerror = (error) => reject(error);
    });
};
const CroppingAvatar = ({ img }) => {
    const fileRef = createRef();
    const [uploaded, setUploaded] = useState(null);
    const [cropped, setCropped] = useState(null);
    const [cropperInstance, setCropperInstance] = useState();
    const onFileInputChange = (e) => {
        const file = e.target?.files?.[0];
        if (file) {
            file2Base64(file).then((base64) => {
                setUploaded(base64);
            });
        }
    };
    const onCrop = () => {
        setUploaded(null);
        setCropped(cropperInstance.getCroppedCanvas().toDataURL());
        const blobField = document.querySelector('#members_user_profile_form__profilePictureBlob');
        if (blobField) {
            blobField.value = cropperInstance.getCroppedCanvas().toDataURL();
        }
    };
    return (<>
            <div style={{ width: '100%' }}>
                {(uploaded && !cropped) ?
            <div>
                            <Cropper style={{ height: 'auto', width: '100%' }} zoomTo={1} dragMode='move' initialAspectRatio={1} aspectRatio={1} src={uploaded} viewMode={3} autoCropArea={0.6} checkOrientation={false} onInitialized={(instance) => {
                    setCropperInstance(instance);
                }} guides={true}/>
                            <a onClick={onCrop}>Crop</a>
                        </div>
            :
                <>
                            <input type="file" style={{ display: 'none' }} ref={fileRef} onChange={onFileInputChange} accept="image/png,image/jpeg"/>
                            <div className="cursor-pointer" onClick={() => {
                        setCropped(null);
                        fileRef.current?.click();
                    }}>
                                {cropped ? <img src={cropped} alt="Cropped!"/> : <div className={'relative m-auto'} style={{ width: '150px', height: '150px' }}>
                                    <a className="absolute right-1 bg-white rounded-full w-6 h-6 bg-brand-primary" style={{ top: '10px', right: '5px' }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="m-[2px] text-white" viewBox="0 0 20 20" fill="currentColor">
                                            <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"/>
                                        </svg>
                                    </a>
                                    {parse(img)}
                                </div>}
                            </div>
                        </>}
            </div>
        </>);
};
CroppingAvatar.displayName = 'CroppingAvatar';
export default CroppingAvatar;
