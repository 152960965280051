import * as React from 'react';
import { useEffect, useState } from 'react';
import MediaSidebarElement from './MediaSidebarElement';
import { getMediaElements } from '../../../Utilities/mediaElements';
import pThrottle from 'p-throttle';
const throttle = pThrottle({
    limit: 1,
    interval: 200,
});
const MediaSidebarList = ({ searchKey }) => {
    const [videos, setVideos] = useState([]);
    const [fetchedData, setFetchedData] = useState(false);
    const fetchElements = throttle(async () => {
        getMediaElements(searchKey, '', [], [], '', '5').then(({ elements }) => {
            setVideos(elements);
            setFetchedData(true);
        });
    });
    useEffect(() => {
        if (!fetchedData) {
            fetchElements();
        }
    }, [fetchedData, videos]);
    const mediaSidebarElements = [];
    for (const video of videos) {
        mediaSidebarElements.push(<MediaSidebarElement key={video.id} video={video}/>);
    }
    return <section className="overflow-hidden rounded-lg bg-gray-100 shadow">
        <div className="px-4 py-5">
            <h3 className="text-base font-semibold leading-6 text-gray-900">Das könnte dir auch gefallen</h3>
        </div>
        <div>
            {mediaSidebarElements}
        </div>
    </section>;
};
MediaSidebarList.displayName = 'MediaSidebar';
export default MediaSidebarList;
