import { useEffect, useRef } from 'react';
/**
 *
 * @param callback function to be executed for hooks like setState
 * @param delay delay in ms
 */
export const useInterval = (callback, delay) => {
    const previousIntervalCallback = useRef();
    useEffect(() => {
        previousIntervalCallback.current = callback;
    }, [callback]);
    useEffect(() => {
        // @ts-ignore todo
        const tick = () => previousIntervalCallback.current();
        const id = setInterval(tick, delay);
        return () => clearInterval(id);
    }, [delay]);
};
