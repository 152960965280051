import * as React from 'react';
import ReactModal from 'react-modal';
import { useState } from 'react';
import parse from 'html-react-parser';
import forms from '../../Functions/forms';
import { Document as PDF, pdfjs, Page } from 'react-pdf/dist/esm/entry.webpack';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
const customStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        zIndex: 99999,
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: '0',
        width: '100%',
        maxWidth: '650px',
        minHeight: '350px',
        maxHeight: '100%',
    }
};
const customStylesRoundedBorder = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        zIndex: 99999,
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: '0',
        width: '100%',
        maxWidth: '650px',
        minHeight: '350px',
        maxHeight: '100%',
        border: '',
        borderRadius: '30px',
    }
};
const SnippetModal = ({ html, isPdf, dataAttribute, header, modalLayout }) => {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [numPages, setNumPages] = useState(null);
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }
    const selector = `[data-attribute='${dataAttribute}']`;
    document.querySelectorAll(selector).forEach((element) => {
        element.addEventListener('click', openModal);
    });
    function openModal() {
        setIsOpen(true);
        forms();
    }
    function closeModal() {
        setIsOpen(false);
    }
    const defaultHeader = <header className="flex flex-row p-8" style={{
            background: 'linear-gradient(to right, ' + (header.gradient1 || 'white') + ', ' + (header.gradient2 || 'white') + ')'
        }}>
        <div className="w-3/5">
            {header.headline ? <h1 className='font-bold text-white text-3xl'>{header.headline}</h1> : ''}
            {header.subline ? <h2 className='text-white font-light pt-2'>{header.subline}</h2> : ''}
        </div>
        <div className="w-2/5 m-auto">
            {parse(header.modalLogoImage)}
        </div>
        <svg onClick={closeModal} xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12"/>
        </svg>
    </header>;
    let headerClass = 'blue1';
    const textColor = modalLayout === 'ergonomie' ? 'text-black' : 'text-white';
    switch (modalLayout) {
        case 'active':
            headerClass = 'lightblue1';
            break;
        case 'ergonomie':
            headerClass = 'yellow1';
            break;
        case 'pain':
            headerClass = 'red1';
            break;
        case 'nutrition':
            headerClass = 'green1';
            break;
    }
    const layoutHeader = <header className={`flex flex-row p-8 bg-brand-${headerClass} relative`}>
        {header.modalBackgroundImage !== '' ? parse(header.modalBackgroundImage) : ''}
        <div className="w-full text-center z-10">
            {header.headline ? <h1 className={`font-bold ${textColor} text-3xl`}>{header.headline}</h1> : ''}
            {header.subline ? <h2 className={`${textColor} font-light pt-2`}>{header.subline}</h2> : ''}
        </div>
        <svg onClick={closeModal} xmlns="http://www.w3.org/2000/svg" className={`h-6 w-6 ${textColor} cursor-pointer z-10`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12"/>
        </svg>
    </header>;
    return <ReactModal isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel={'Modal'} style={modalLayout === 'default' ? customStyles : customStylesRoundedBorder}>
        <section>
            {modalLayout === 'default' ? defaultHeader : layoutHeader}
            <main>
                {isPdf ? <PDF file={{ url: html }} onLoadSuccess={onDocumentLoadSuccess}>
                    {Array.from(new Array(numPages), (el, index) => (<React.Fragment key={index}>
                                <Page key={`page_${index + 1}`} pageNumber={index + 1} width={500} className={'m-auto'}/>
                                <br />
                                <hr />
                                <br />
                            </React.Fragment>))}
                </PDF> : parse(html)}
            </main>
        </section>
    </ReactModal>;
};
SnippetModal.displayName = 'SnippetModal';
export default SnippetModal;
