import * as React from 'react';
import BallIcon from '../Icons/BallIcon';
import FaszienrolleIcon from '../Icons/FaszienrolleIcon';
import MatteIcon from '../Icons/MatteIcon';
import MinibandIcon from '../Icons/MinibandIcon';
import TherabandIcon from '../Icons/TherabandIcon';
const switchIcon = (iconName) => {
    switch (iconName) {
        case 'ball':
            return <BallIcon />;
        case 'faszienrolle':
            return <FaszienrolleIcon />;
        case 'matte':
            return <MatteIcon />;
        case 'miniband':
            return <MinibandIcon />;
        case 'theraband':
            return <TherabandIcon />;
    }
    throw new Error('This icon does not exist');
};
const Icon = ({ iconName }) => {
    return <>
        {switchIcon(iconName)}
    </>;
};
Icon.displayName = 'Icon';
export default Icon;
