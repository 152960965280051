import * as React from 'react';
import PermissionDeniedEvent from '../../../../Events/PermissionDeniedEvent';
const ProfileVideoModalSidebarVideo = ({ video, additionalClassName, lockWithPreview, isCurrent, handleClick }) => {
    return <a role={video.isLocked ? 'button' : undefined} onClick={video.isLocked ? (e) => {
            e.preventDefault();
            PermissionDeniedEvent();
        } : () => handleClick()} title={video.title} className={'flex flex-row items-start my-3 py-2 hover:bg-gray-200 cursor-pointer ' + additionalClassName + (isCurrent ? ' bg-gray-200 ' : '') || ''}>
        <div className="ml-4 relative w-full basis-1/3">
            {video.isLocked ?
            <>
                    {lockWithPreview ? (<div className={'min-h-[180px] relative'}>
                        <div className={'absolute bg-black opacity-80 top-0 bottom-0 right-0 left-0 z-10'}/>
                        <img loading={'lazy'} src={video.data.thumbnail} className={'absolute w-full bg-gray-100 rounded-lg'}/>
                    </div>) : <div className={'min-h-[180px] bg-black opacity-80'}/>}
                </> :
            <img loading={'lazy'} src={video.data.thumbnail} className={'w-full bg-gray-100 rounded-lg'}/>}

            <div className="absolute top-0 flex items-center align-center justify-center w-full h-full">
                {video.isLocked ? <>
                    <i className={'fa fa-lock text-white text-2xl ' + (lockWithPreview ? 'z-10' : '')}/>
                </> : <>
                    {video.data.type === 'youtube' || video.data.type === 'video' ?
                <i className="fa fa-play text-white text-sm"/> : ''}
                </>}
            </div>
            <div className="absolute bottom-0 flex justify-end px-1 pb-1 w-full">
                {video.durationString != ''
            ? <span className="relative z-10 rounded-md bg-gray-700 text-white px-1 py-0.5 text-xs font-normal">{video.durationString}</span>
            : <span></span>}
            </div>
        </div>
        <div className="group relative max-w-xl basis-2/3 px-2">
            <div className="text-sm font-semibold">
                {video.title}
            </div>
        </div>
    </a>;
};
ProfileVideoModalSidebarVideo.displayName = 'ProfileVideoModalSidebarVideo';
export default ProfileVideoModalSidebarVideo;
