import * as React from 'react';
import BaseElement from '../../CustomElements/BaseElement';
import { useI18n } from '../../../Translation';
const ActivatedModuleOverview = ({ modules }) => {
    const i18n = useI18n();
    return <>
        <div className="pb-5">
            <h3 className="mt-5 text-xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                {i18n['profile.profileModulesHeading']}
            </h3>
        </div>

        <div className="grid grid-cols-1 gap-5 sm:grid-cols-3">
            {modules.map((module, i) => {
            return <div key={'module-' + i} className="overflow-hidden relative rounded-lg bg-white shadow-lg">
                    <>
                        <div className="relative">
                            <div className="relative h-72 w-full overflow-hidden rounded-lg rounded-b-none">
                                {module.image ?
                    <img src={module.image} className="h-full w-full object-cover object-center" alt={module.title}/> : <></>}
                            </div>

                            <div className="absolute inset-x-0 top-0 h-72 overflow-hidden flex flex-col justify-end rounded-lg rounded-b-none p-4 sm:px-6">
                                <div aria-hidden="true" className="absolute inset-x-0 bottom-0 h-36 bg-gradient-to-t from-black opacity-75"></div>
                                <div className="relative flex justify-between items-center">
                                    <span className="text-xl font-semibold tracking-tight text-white">{module.title}</span>
                                </div>
                                <div className="relative mt-4">
                                    <div className="flex flex-col justify-center">
                                        <ul>
                                            <li className="text-white">{i18n['profile.modules.availableSince']}: {module.availableSince}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="relative inset-x-0 bottom-0 bg-gray-50 p-4 sm:px-6">
                            <div className="text-sm flex justify-between items-center">
                                {/**
                 <a href={module.deactivateUrl}
                 className="text-sm font-semibold leading-6 text-[#00537e] rounded bg-[#00537e]/[.08] px-2 py-1 shadow-sm hover:text-white hover:bg-[#00537e]">
                 {i18n['profile.healthScore.startTest']}
                 </a>
                 **/}
                                <a href={module.landingPageUrl} className="text-sm font-semibold leading-6 text-[#00537e] rounded bg-[#00537e]/[.08] px-2 py-1 shadow-sm hover:text-white hover:bg-[#00537e]">
                                    {module.landingPageLinkTitle || i18n['profile.modules.landingPageUrl']}
                                </a>
                            </div>
                        </div>
                    </>
                </div>;
        })}
        </div>
    </>;
};
ActivatedModuleOverview.displayName = 'ActivatedModuleOverview';
export default ActivatedModuleOverview;
class ActivatedModuleOverviewElement extends BaseElement {
    constructor() {
        super(...arguments);
        this.element = ActivatedModuleOverview;
    }
}
export const loadActivatedModuleOverviewElement = () => {
    customElements.define('activated-module-overview', ActivatedModuleOverviewElement);
};
