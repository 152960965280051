import * as React from 'react';
import { useEffect, useState } from 'react';
import CustomSlider from './CustomSlider';
import MediaElement from './MediaElement';
import BaseElement from '../../CustomElements/BaseElement';
import { generateUrl } from '../../../Utilities/api';
const CustomVideoSlider = ({ videoSliderId, disableInfinity, title }) => {
    const [videos, setVideos] = useState([]);
    useEffect(() => {
        fetch(generateUrl('app_videoslider_fetchvideosofvideoslider', { videoSliderId: videoSliderId }))
            .then((res) => res.json())
            .then((json) => setVideos(json.videos));
    }, []);
    const videoElements = [];
    for (const video of videos) {
        videoElements.push(<MediaElement key={video.id} video={video}/>);
    }
    if (videoElements.length === 0) {
        return <></>;
    }
    return <div className="bg-white container px-8 m-auto">
        <div className="m-auto">
            {title ? <header className="my-4">
                <h1 className="font-bold text-3xl mb-2 text-brand-primary">{title}</h1>
            </header> : <></>}
            <CustomSlider slidesXl={4} slidesLg={3} slidesMd={2} slidesSm={1} infinity={!disableInfinity} count={videoElements.length}>
                {videoElements}
            </CustomSlider>
        </div>
    </div>;
};
CustomVideoSlider.displayName = 'CustomVideoSlider';
class CustomVideoSliderElement extends BaseElement {
    constructor() {
        super(...arguments);
        this.element = CustomVideoSlider;
    }
}
export const loadCustomVideoSlider = () => {
    customElements.define('custom-video-slider', CustomVideoSliderElement);
};
