import BaseElement from '../../CustomElements/BaseElement';
import * as React from 'react';
import { UserContext } from '../../Context/UserContext';
import { useState } from 'react';
import ReactModal from 'react-modal';
import { useI18n } from '../../../Translation';
import { generateUrl } from '../../../Utilities/api';
const AccountDeletionButton = () => {
    const i18n = useI18n();
    const user = React.useContext(UserContext);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    if (user === null) {
        return <button is="no-permission-button" className="flex text-lg font-bold shadow-md p-4 py-2 my-2 border border-transparent bg-brand-secondary rounded-full text-white hover:opacity-80">{i18n['profile.account.delete-button.no-permission']}</button>;
    }
    return <>
        <AccountDeletionModal title={i18n['profile.account.delete-modal.title']} buttonTitle={i18n['profile.account.delete-modal.button-title']} description={i18n['profile.account.delete-modal.description']} confirmText={i18n['profile.account.delete-modal.confirm-text']} isOpen={modalIsOpen} setIsOpen={() => setModalIsOpen(!modalIsOpen)}/>
        <button onClick={() => setModalIsOpen(true)} className="inline-flex items-center gap-x-2 rounded-md px-3.5 py-2.5 text-base font-semibold text-white shadow-sm bg-red-500 hover:bg-red-600 md:bottom-24 md:right-12">
            {i18n['profile.account.delete-button.title']}
        </button>
    </>;
};
const customStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        zIndex: 99999,
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: '0',
        width: '100%',
        maxWidth: '750px',
        minHeight: '350px',
        maxHeight: '100%',
    }
};
const AccountDeletionModal = (props) => {
    const user = React.useContext(UserContext);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const compareWithUsername = (value) => {
        if (user.username === value) {
            setButtonDisabled(false);
        }
        else if (!buttonDisabled) {
            setButtonDisabled(true);
        }
    };
    return <ReactModal isOpen={props.isOpen} onRequestClose={props.setIsOpen} shouldCloseOnOverlayClick={false} contentLabel={'Modal'} style={customStyles}>
        <section className="p-8">
            <main>
                <div className="mt-5 sm:mt-6 text-center sm:mt-5">
                    <h2 className="text-xl font-semibold leading-6 text-gray-900" id="modal-title">
                        {props.title}
                    </h2>
                    <div className="prose mt-5 sm:mt-6">
                        <p className="text-base text-gray-900">
                            {props.description}
                        </p>
                        <br />
                        <p className="text-base text-gray-900">
                            <b>{props.confirmText}</b>
                            <br />
                            <small>{user.username}</small>
                        </p>
                        <input className="block w-full rounded-md border border-gray-400 p-1.5 text-base text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2" onChange={(event) => compareWithUsername(event.target.value)}/>
                    </div>
                </div>
            </main>
            <footer>
                <div className="mt-5 sm:mt-6">
                    {buttonDisabled ? <button disabled={true} className="inline-flex w-full flex text-lg font-bold shadow-md items-center justify-center p-4 py-2 my-2 border border-transparent bg-red-300 rounded-full text-white m-auto">
                        {props.buttonTitle}
                    </button> :
            <a href={generateUrl('app_myarea_accountdeletion')} className="inline-flex w-full flex text-lg font-bold shadow-md items-center justify-center p-4 py-2 my-2 border border-transparent bg-red-500 hover:bg-red-600 rounded-full text-white m-auto">
                            {props.buttonTitle}
                        </a>}
                </div>
            </footer>
        </section>
    </ReactModal>;
};
class AccountDeletionButtonElement extends BaseElement {
    constructor() {
        super(...arguments);
        this.element = AccountDeletionButton;
    }
}
export const loadAccountDeletionButton = () => {
    customElements.define('account-deletion-button', AccountDeletionButtonElement);
};
