import BaseElement from './BaseElement';
import GlobalSearchResultGrid from '../Components/GlobalSearch/GlobalSearchResultGrid';
class GlobalSearchResultGridElement extends BaseElement {
    constructor() {
        super(...arguments);
        this.element = GlobalSearchResultGrid;
    }
}
export const loadGlobalSearchResultGrid = () => {
    customElements.define('global-search-grid', GlobalSearchResultGridElement);
};
