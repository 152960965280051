import { createRoot } from 'react-dom/client';
import * as React from 'react';
import VideoPlayer from './Components/VideoPlayer';
import ConfiguratorModal from './Components/Configurator/ConfiguratorModal';
import * as Modal from 'react-modal';
import Liveplayer from './Components/Players/Liveplayer';
import Mediathek from './Components/Mediathek/Mediathek';
import TestimonialSlider from './Components/TestimonialSlider';
import CountdownComponent from './Components/Countdown';
import CommentList from './CustomElements/CommentList';
import { Toaster } from 'react-hot-toast';
import PriceUserAmountSlider from './CustomElements/PriceUserAmountSlider';
import AvatarSelector from './CustomElements/AvatarSelector';
import SnippetModalElement from './CustomElements/SnippetModal';
import PermissionDeniedModal from './CustomElements/PermissionDeniedModal';
import { NoPermissionButton, NoPermissionLink } from './CustomElements/NoPermissionButton';
import CompanyColorChanger from './CustomElements/CompanyColorChanger';
import VideoSeriesPlayer from './CustomElements/VideoSeriesPlayer';
import CountdownTimer from './CustomElements/CountdownTimer';
import IconSetElement from './CustomElements/IconSet';
import FavouriteButtonElement from './CustomElements/FavoriteButton';
import MediaSidebarListElement from './CustomElements/MediaSidebarList';
import GlobalSearchElement from './CustomElements/GlobalSearch';
import { loadOnboardingModal } from './Components/OnboardingModal';
import { loadAlertWithIcon } from './Components/AlertWithIcon';
import { loadMediaElementWebComponent } from './CustomElements/SingleMediaElement';
import AccordionElement from './CustomElements/Accordion';
import { loadGlobalSearchResultGrid } from './CustomElements/GlobalSearchResultGrid';
import InsuranceDiscountSearch from './Components/InsuranceDiscountSearch';
import { createElement } from './Utils/createElement';
import SupportWidgetElement from './CustomElements/SupportWidget';
import CookieConsentModalElement from './CustomElements/CookieConsentModal';
import { loadProfileStatsElementWebComponent } from './CustomElements/ProfileStats';
import { loadProfileVideoCategoryContainerElementWebComponent } from './CustomElements/ProfileVideoCategoryContainer';
import { loadProfileScoreContainerElementWebComponent } from './CustomElements/ProfileScoreContainer';
import { loadToolTipWebComponent } from './CustomElements/ToolTip';
import { loadLiveplayerSlider } from './Components/Mediathek/LiveplayerSlider';
import { loadChallengePlayerWebComponent } from './CustomElements/ChallengePlayer';
import { loadNotificationSubscriptionToggle } from './Components/Notification/NotificationSubscriptionToggle';
import { loadNotificationSubscriptionButton } from './Components/Notification/NotificationSubscriptionButton';
import { loadModuleActivationButton } from './Components/Module/ModuleButton';
import { loadVideoChallengeModalElement } from './Components/Challenge/VideoChallengeModal';
import { loadActivatedModuleOverviewElement } from './Components/Profile/ActivatedModuleOverview';
import { loadAccountDeletionButton } from './Components/Profile/AccountDeletionButton';
import { loadCustomVideoSlider } from './Components/Mediathek/CustomVideoSlider';
import { loadCourseElement } from './Components/Courses/Course';
import { loadCourseLectureItemListElement } from './Components/Courses/CourseLectureItemList';
import { loadCourseProgressbarElement } from './Components/Courses/CourseProgressbar';
import { loadCourseOverviewElement } from './Components/Courses/CourseOverview';
import { loadCoursePaymentElement } from './Components/Courses/CoursePaymentElement';
export default () => {
    const notificationNode = document.querySelector('.js-notification');
    if (notificationNode) {
        const rootElement = createRoot(notificationNode);
        rootElement.render(React.createElement(Toaster));
    }
    customElements.define('comment-list', CommentList);
    customElements.define('price-user-amount-slider', PriceUserAmountSlider);
    customElements.define('avatar-selector', AvatarSelector);
    customElements.define('snippet-modal', SnippetModalElement);
    customElements.define('permission-denied', PermissionDeniedModal);
    customElements.define('no-permission-button', NoPermissionButton, { extends: 'button' });
    customElements.define('no-permission-link', NoPermissionLink, { extends: 'a' });
    customElements.define('company-color-changer', CompanyColorChanger);
    customElements.define('video-series-player', VideoSeriesPlayer);
    customElements.define('countdown-timer', CountdownTimer);
    customElements.define('icon-set', IconSetElement);
    customElements.define('favorite-button', FavouriteButtonElement);
    customElements.define('media-sidebar-list', MediaSidebarListElement);
    customElements.define('global-search', GlobalSearchElement);
    customElements.define('accordion-element', AccordionElement);
    customElements.define('support-widget', SupportWidgetElement);
    customElements.define('cookie-consent-modal', CookieConsentModalElement);
    loadGlobalSearchResultGrid();
    loadOnboardingModal();
    loadAlertWithIcon();
    loadMediaElementWebComponent();
    loadProfileStatsElementWebComponent();
    loadProfileVideoCategoryContainerElementWebComponent();
    loadProfileScoreContainerElementWebComponent();
    loadToolTipWebComponent();
    loadLiveplayerSlider();
    loadChallengePlayerWebComponent();
    loadNotificationSubscriptionToggle();
    loadNotificationSubscriptionButton();
    loadModuleActivationButton();
    loadVideoChallengeModalElement();
    loadActivatedModuleOverviewElement();
    loadAccountDeletionButton();
    loadCustomVideoSlider();
    loadCourseElement();
    loadCourseLectureItemListElement();
    loadCourseProgressbarElement();
    loadCourseOverviewElement();
    loadCoursePaymentElement();
    document.querySelectorAll('.js-react-videoplayer').forEach((element) => {
        createElement(element, VideoPlayer, JSON.parse(element.dataset?.props || '{}'));
    });
    document.querySelectorAll('.sitzkrieger__configurator__root').forEach((node) => {
        try {
            const settings = JSON.parse(node.dataset?.settings || '{}');
            createElement(node, ConfiguratorModal, {
                isGranted: settings?.isGranted || false,
                questionId: settings?.question || null,
                labels: settings?.labels || {},
                clickAttribute: settings?.clickAttribute,
                useClassInsteadOfAttribute: settings?.useClassInsteadOfAttribute || false,
                showEndcardText: settings?.showEndcardText || false,
                modalLayout: settings?.modalLayout || 'workout',
                modalBackgroundImage: settings?.modalBackgroundImage || '',
            });
            // @ts-ignore
            Modal.setAppElement(node);
        }
        catch (e) {
            console.error(e);
        }
    });
    document.querySelectorAll('.sitzkrieger__testimonial__root').forEach((node) => {
        const settings = JSON.parse(node.dataset?.settings || '{}');
        createElement(node, TestimonialSlider, settings);
    });
    document.querySelectorAll('.sitzkrieger__liveplayer__root').forEach((node) => {
        const settings = JSON.parse(node.dataset?.settings || '{}');
        createElement(node, Liveplayer, settings);
    });
    document.querySelectorAll('.js-sitzkrieger-countdown').forEach((node) => {
        const settings = JSON.parse(node.dataset?.settings || '{}');
        createElement(node, CountdownComponent, settings);
    });
    document.querySelectorAll('.sitzkrieger__mediathek__root').forEach((node) => {
        const settings = JSON.parse(node.dataset?.settings || '{}');
        createElement(node, Mediathek, settings);
    });
    const discountSearch = document.querySelectorAll('.js-praeventionskurse-discount-search');
    discountSearch.forEach((node) => {
        const settings = JSON.parse(node.dataset?.json || '{}');
        const parentNode = node.parentNode;
        const data = { discounts: settings?.discounts ?? {}, parentNode: parentNode };
        // @ts-ignore
        createElement(node, InsuranceDiscountSearch, data);
    });
};
