import * as React from 'react';
import PermissionDeniedEvent from '../../../Events/PermissionDeniedEvent';
const ChallengeMediaElement = ({ video, videoChallenge, additionalClassName, mediaElementClickCallback, }) => {
    const link = video.isLocked || typeof mediaElementClickCallback === 'function' ? 'javascript:void(0)' : '/w/' + video.id;
    return <a href={link} role={video.isLocked ? 'button' : undefined} onClick={video.isLocked ? (e) => {
            e.preventDefault();
            PermissionDeniedEvent({
                title: videoChallenge.title,
                description: videoChallenge.permissionDeniedMessage || 'Dieses Video wurde noch nicht freigeschaltet.',
                showFooter: false,
            });
        } : (typeof mediaElementClickCallback === 'function' ? () => mediaElementClickCallback(Number(video.id)) : undefined)} title={video.title} className={'flex flex-col items-start justify-between bg-gray-100 rounded-lg drop-shadow-md ' + additionalClassName || ''} 
    // disabling this for now, might add back in the future style={{backgroundColor: video.videoBoxColor, color: video.videoTextColor}}>
    style={{ backgroundColor: '#f4f4f4', color: '#000000' }}>
        <div className="relative w-full">
            <img loading={'lazy'} src={video.data.thumbnail} className={'min-h-[180px] w-full bg-gray-100 rounded-lg aspect-video'}/>

            <div className={'absolute top-0 flex items-center align-center justify-center rounded-lg w-full h-full hover:cursor-pointer ' + (video.isLocked ? 'bg-gray-700 bg-opacity-50 transition-opacity' : '')}>
                {video.isLocked ? (<>
                    <i className={'fa fa-lock text-red-600 z-30 text-5xl'}/>
                </>) : video.watched ? (<>
                        <i className={'fa fa-check text-green-600 text-6xl z-30'}/>
                    </>) : (<>
                    {video.data.type === 'youtube' || video.data.type === 'video' ?
                <i className="fa fa-play text-white z-30 text-4xl"/> : ''}
                </>)}
            </div>

            <div className="absolute bottom-0 flex justify-between px-2 pb-2 w-full">
                <span></span>
                {video.durationString != ''
            ? <span className="relative z-10 rounded-md bg-gray-700 text-white px-1 py-0.5 text-xs font-normal">{video.durationString}</span>
            : <span></span>}
            </div>
        </div>
    </a>;
};
ChallengeMediaElement.displayName = 'ChallengeMediaElement';
export default ChallengeMediaElement;
