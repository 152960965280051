import PermissionDeniedEvent from '../../Events/PermissionDeniedEvent';
export class NoPermissionButton extends HTMLButtonElement {
    constructor() {
        super();
        this.addEventListener('click', () => PermissionDeniedEvent());
    }
}
export class NoPermissionLink extends HTMLAnchorElement {
    constructor() {
        super();
        this.addEventListener('click', () => PermissionDeniedEvent());
    }
}
