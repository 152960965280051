import BaseElement from './BaseElement';
import ProfileVideoCategoryContainer from '../Components/Profile/ProfileVideoCategoryContainer';
class ProfileVideoCategoryContainerElement extends BaseElement {
    constructor() {
        super(...arguments);
        this.element = ProfileVideoCategoryContainer;
    }
}
export const loadProfileVideoCategoryContainerElementWebComponent = () => {
    customElements.define('profile-video-category-container', ProfileVideoCategoryContainerElement);
};
