import * as React from 'react';
import { useState, useRef, useEffect } from 'react';
import ReactPlayer from 'react-player';
import VideoChapters from '../Mediathek/VideoChapters';
import { UserContext } from '../../Context/UserContext';
const DefaultReactPlayer = ({ playerConfig, sourceConfig, onStart, onProgress, onEnded, height, width, }) => {
    const [playedSeconds, setPlayedSeconds] = useState(0);
    const [duration,] = useState(0);
    const player = useRef(null);
    const user = React.useContext(UserContext);
    const [showGdprWarning, setShowGdprWarning] = useState(false);
    useEffect(() => {
        setShowGdprWarning(user === null);
    }, []);
    const handleSeekTo = (event) => {
        if (player.current) {
            player.current.seekTo(parseFloat(event.currentTarget.value));
        }
    };
    const handleProgress = (state) => {
        setPlayedSeconds(state.playedSeconds);
        onProgress(state);
        if (playerConfig.hasOwnProperty('onProgress')) {
            playerConfig.onProgress();
        }
    };
    const handleDuration = (duration) => {
        setPlayedSeconds(duration);
    };
    if (sourceConfig.file?.forceVideo) {
        return <>
            <ReactPlayer {...playerConfig} ref={player} onProgress={handleProgress} onDuration={handleDuration} onEnded={() => {
                if (playedSeconds > 1) {
                    onEnded();
                }
            }} onReady={onStart} onClickPreview={() => setShowGdprWarning(false)} width={width ?? '100%'} height={height ?? '500px'} config={{
                file: sourceConfig.file,
            }}/>
            {playerConfig.chapters && playerConfig.chapters.length > 0
                ? <VideoChapters videoChapters={playerConfig.chapters} handleSeekTo={event => handleSeekTo(event)} playedSeconds={playedSeconds} duration={duration}></VideoChapters>
                : ''}
        </>;
    }
    return <>
        <ReactPlayer {...playerConfig} ref={player} onProgress={handleProgress} onDuration={handleDuration} onEnded={() => {
            if (playedSeconds > 1) {
                onEnded();
            }
        }} onReady={onStart} onClickPreview={() => setShowGdprWarning(false)} width={width ?? '100%'} height={height ?? '500px'} config={{
            youtube: {
                playerVars: {
                    rel: 1,
                    showinfo: 1,
                    iv_load_policy: 1,
                    modestbranding: 1,
                    cc_load_policy: 1,
                },
            },
            ...sourceConfig
        }}/>
        {showGdprWarning ? <span>Bei Klick wird dieses Video von den YouTube-Servern geladen. Details siehe Datenschutzerklärung.</span> : ''}
        {playerConfig.chapters && playerConfig.chapters.length > 0
            ? <VideoChapters videoChapters={playerConfig.chapters} handleSeekTo={event => handleSeekTo(event)} playedSeconds={playedSeconds} duration={duration}></VideoChapters>
            : ''}
    </>;
};
DefaultReactPlayer.displayName = 'ReactPlayer';
export default DefaultReactPlayer;
