import * as React from 'react';
// @types/react-slick
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
export const CustomSlider = ({ slidesXl, slidesLg, slidesMd, slidesSm, children, infinity, count }) => {
    const sliderSettings = {
        dots: false,
        infinite: infinity,
        slidesToShow: slidesXl,
        slidesToScroll: 1,
        rows: 1,
        swipeToSlide: false,
        draggable: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: slidesLg,
                    swipeToSlide: true,
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: slidesMd,
                    swipeToSlide: true,
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: slidesSm,
                    swipeToSlide: true,
                }
            }
        ]
    };
    return <Slider {...sliderSettings}>
        {children}
    </Slider>;
};
CustomSlider.displayName = 'CustomSlider';
export default CustomSlider;
