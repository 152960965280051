import * as React from 'react';
import FavoriteButton from './FavoriteButton';
import IconSet from './IconSet';
import Accordion from './Accordion';
const MediaElementDetails = ({ video }) => {
    return <>
        <div className="rounded-lg bg-gray-100 shadow p-6">
            <div className="mb-4 flex items-center">
                <FavoriteButton infavorites={video.inFavourites} videoid={parseInt(video.id)}/>
            </div>
            <div className="mb-4">
                <h1 className="text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                    {video.title}
                </h1>
                {video.subtitle &&
            <h3 className="text-xl mb-2 font-bold">{video.subtitle}</h3>}
            </div>
            <div className="mb-8">
                <dl className="grid grid-cols-2 sm:grid-cols-4 gap-y-4">
                    {video.durationString && video.durationString.length > 0 &&
            <div>
                            <dt className="mb-2 font-cabin text-xs font-medium">Videolänge</dt>
                            <dd className="text-sm font-semibold">{video.durationString}</dd>
                        </div>}

                    {video.creationDate && video.creationDate.length > 0 &&
            <div>
                            <dt className="mb-2 font-cabin text-xs font-medium">Veröffentlicht</dt>
                            <dd className="text-sm font-semibold">
                                {(new Date(video.creationDate)).toLocaleDateString('de', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric'
                })}
                            </dd>
                        </div>}
                    {video.chapterIcons && video.chapterIcons.length > 0 &&
            <div>
                            <dt className="mb-2 font-cabin text-xs font-medium">Inhalte</dt>
                            <dd className="text-sm font-semibold">
                                <div className="flex items-center">
                                    <IconSet icons={video.chapterIcons}/>
                                </div>
                            </dd>
                        </div>}

                    {video.gymEquipments && video.gymEquipments.length > 0 &&
            <div>
                            <dt className="mb-2 font-cabin text-xs font-medium">Geräte</dt>
                            <dd className="text-sm font-semibold">
                                <IconSet icons={video.gymEquipments}/>
                            </dd>
                        </div>}
                </dl>
            </div>

            {video.tags && video.tags.length > 0 && (<div className="mb-2 mt-8 flex space-x-2 items-center">
                    {video.tags.map((tag, index) => (<a key={index} href={`/global-search/result/?tags=${tag.title}`} className="inline-flex items-center rounded-md bg-cyan-50 px-2 py-1 text-xs font-medium text-cyan-700 ring-1 ring-inset ring-cyan-700/10">
                            {tag.title}
                        </a>))}
                </div>)}

            {video.description && video.description.length &&
            <Accordion label={'Mehr Infos zum Video'} content={video.description}/>}
        </div>
    </>;
};
MediaElementDetails.displayName = 'MediaElementDetails';
export default MediaElementDetails;
